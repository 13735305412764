import { useState } from 'react';
import { CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons';

import { trackTagFilterButtonClicked } from '@/lib/analytics-event';
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';

const FilterValuePopover = ({
  filterValues,
  updateFilterValue,
  selectedFilterValues,
  filterFieldName,
}) => {
  const [filterValuesPopoverOpen, setFilterValuesPopoverOpen] = useState(false);

  const labelsList = {
    user_id: 'Owners',
    tag_ids: 'Tags',
    plan: 'Plans',
  };

  const popoverButtonLabel = labelsList[filterFieldName];

  // Function to add an array item to the value list of the filter
  const addItem = (newItem) => {
    updateFilterValue([...selectedFilterValues, newItem]);
  };

  // Function to remove an array item to the value list of the filter
  const removeItem = (itemToRemove) => {
    const updatedItems = selectedFilterValues.filter(
      (item) => item !== itemToRemove
    );
    updateFilterValue(updatedItems);
  };

  // Function to get the right attribute for the mapped options
  const getPopoverItemLabel = (option) => {
    const namePropertyList = {
      user_id: `${option.first_name} ${option.last_name}`,
      tag_ids: `${option.name}`,
      plan: `${option.name}`,
    };
    return namePropertyList[filterFieldName];
  };

  return (
    <Popover
      open={filterValuesPopoverOpen}
      onOpenChange={setFilterValuesPopoverOpen}
    >
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          //   size='sm'
          className='w-full select-none border-dashed'
          onClick={() => trackTagFilterButtonClicked('mouse')}
        >
          <PlusCircledIcon className='mr-2 h-4 w-4' />
          {popoverButtonLabel}
          {selectedFilterValues?.length > 0 && (
            <>
              <Separator orientation='vertical' className='mx-1 h-4' />
              <Badge
                variant='secondary'
                className='rounded-sm px-1 font-normal'
              >
                {selectedFilterValues.length}
              </Badge>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className='w-[200px] p-0'
        align='start'
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        {filterValues.length > 0 ? (
          <Command>
            <CommandInput />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                <ScrollArea className='h-[250px]'>
                  {filterValues.map((option) => {
                    const isSelected = selectedFilterValues.find(
                      (element) => element === option._id
                    );
                    return (
                      <CommandItem
                        key={option._id}
                        onSelect={() => {
                          if (isSelected) {
                            removeItem(option._id);
                          } else {
                            addItem(option._id);
                          }
                        }}
                      >
                        <div
                          className={cn(
                            'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                            isSelected
                              ? 'bg-primary text-primary-foreground'
                              : 'opacity-50 [&_svg]:invisible'
                          )}
                        >
                          <CheckIcon className={cn('h-4 w-4')} />
                        </div>
                        {option.icon && (
                          <option.icon className='mr-2 h-4 w-4 text-muted-foreground' />
                        )}
                        {}
                        <span>{getPopoverItemLabel(option)}</span>
                      </CommandItem>
                    );
                  })}
                </ScrollArea>
              </CommandGroup>
              {selectedFilterValues.length > 0 && (
                <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem
                      onSelect={() => {
                        updateFilterValue([]);
                      }}
                      className='justify-center text-center'
                    >
                      Clear Filter
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        ) : (
          <div className='py-6 text-center text-sm'>
            {' '}
            No {popoverButtonLabel}
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default FilterValuePopover;
