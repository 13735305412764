import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = {
  _id: null,
  ig_username: null,
  fullname: '',
  status: null,
  profile_picture_url: null,
  load_fb_past_messages: null,
  tags: [],
  owner: null,
  note: null,
  plan: null,

  page: 0,
  messages: [],
};

const useContactStore = create(
  devtools(
    (set) => ({
      ...initialState,

      setContactDetails: (contactData) =>
        set({
          _id: contactData._id,
          ig_username: contactData.ig_username,
          fullname: contactData.full_name,
          status: contactData.status,
          profile_picture_url: contactData.profile_picture_url,
          load_fb_past_messages: contactData.load_fb_past_messages,
          tags: contactData.tags,
          owner: contactData.user_id,
          note: contactData.note,
          plan: contactData.plan,
        }),
      changeContact: (contactId) => set({ _id: contactId }),
      setContactStatus: (status) =>
        set({
          status,
        }),
      setContactOwner: (ownerId) => set({ owner: ownerId }),
      setPlan: (plan) => set({ plan }),
      addContactTag: (newTag) =>
        set((state) => ({ tags: [...state.tags, newTag] })),
      removeContactTag: (tagId) =>
        set((state) => ({
          tags: state.tags.filter((tag) => tag._id !== tagId),
        })),
      addOrRemoveContactTag: (newTag) =>
        set(({ tags }) => {
          if (tags.some((tag) => tag._id === newTag._id)) {
            return {
              tags: tags.filter((tag) => tag._id !== newTag._id),
            };
          } else {
            return {
              tags: [...tags, newTag],
            };
          }
        }),
      setLoadFbPastMessages: (value) => set({ load_fb_past_messages: value }),

      setPage: (page) => set({ page }),
      setMessages: (messages) => set({ messages }),
      setNewMessage: (newMessage) =>
        set((state) => ({ messages: [...state.messages, newMessage] })),
      setPrevPageMessages: (prevMessages) =>
        set((state) => ({ messages: [...prevMessages, ...state.messages] })),
      setMessageReaction: (messageId, emoji) =>
        set(
          (state) => ({
            messages: state.messages.map((message) =>
              message._id === messageId ? { ...message, emoji } : message
            ),
          }),
          false,
          'set_message_reaction'
        ),
      setMessageReadReceipt: (messageId) =>
        set(
          (state) => ({
            messages: state.messages.map((message) =>
              message._id === messageId
                ? { ...message, is_seen: true }
                : message
            ),
          }),
          false,
          'set_message_readreceipt'
        ),

      resetContactStore: () => set({ ...initialState }),
    }),

    { name: 'ContactStore' }
  )
);

export default useContactStore;
