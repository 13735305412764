import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useContactStore from '@/stores/ContactStore';
import useInboxStore from '@/stores/InboxStore';
import usePlanStore from '@/stores/PlanStore';
import useUserStore from '@/stores/UserStore';
import { useHotkeys } from '@blueprintjs/core';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Check, PlusCircle } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import {
  trackContactTagButtonClicked,
  trackTagAdded,
  trackTagRemoved,
} from '@/lib/analytics-event';
import { cn } from '@/lib/utils';
import {
  addTagToContact,
  attachPlanToContact,
  removeTagFromContact,
} from '@/hooks/contact';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { ButtonLoading } from '@/components/common/button/loading-button';
import TooltipComponent from '@/components/common/tooltip/tooltip';

const otherTagConditions = [
  { _id: 'No-Tag-SBCCRM', name: 'No Tags' },
  { _id: 'Seen-Not-Responded', name: 'Seen & Not Responded' },
  { _id: 'Not-Responded', name: 'Not Responded' },
];

const ContactTags = () => {
  const inputRef = React.useRef(null);
  const [inputValue, setInputValue] = React.useState('');

  const { planSelectionModalStatus, setPlanSelectionModalStatus } =
    usePlanStore(
      useShallow((state) => ({
        planSelectionModalStatus: state.planSelectionModalStatus,
        setPlanSelectionModalStatus: state.setPlanSelectionModalStatus,
      }))
    );

  const { id: contactId } = useParams();
  const clientId = useUserStore((state) => state.clientId);

  const { customerTags, addCustomerTags, primaryTag } = useInboxStore(
    useShallow((state) => ({
      customerTags: state.tags,
      addCustomerTags: state.addTags,
      primaryTag: state.primaryTag,
    }))
  );

  const { contactTags, addContactTag, addOrRemoveContactTag } = useContactStore(
    useShallow((state) => ({
      contactTags: state.tags,
      addContactTag: state.addContactTag,
      addOrRemoveContactTag: state.addOrRemoveContactTag,
    }))
  );

  const toggleTag = async (selectedTag) => {
    // Add tag to contact
    const selectedTagExists = contactTags.some(
      (item) => item._id === selectedTag._id
    );
    if (!selectedTagExists) {
      try {
        // Closing popover after selection
        setTagPopoverOpen(false);

        if (selectedTag.name === primaryTag) {
          setPlanSelectionModalStatus(true);

          return;
        }

        // add tag to the contact store
        addOrRemoveContactTag(selectedTag);

        // call API to add tag to contact
        await addTagToContact(contactId, selectedTag.name, clientId);
        trackTagAdded(selectedTag._id, selectedTag.name);

        // const { tag } = response;
        // const tagName = tag.name;

        inputRef?.current?.focus();
      } catch (error) {
        toast.error('Failed to add tag to contact');

        // Undoing the changes by removing the tag from contact store
        addOrRemoveContactTag(selectedTag);

        console.error('Failed to add tag to contact:', error);
      }
    }

    // Remove tag from contact
    if (selectedTagExists) {
      try {
        // Remove tag from contact
        addOrRemoveContactTag(selectedTag);

        // Call API to remove tag from contact
        await removeTagFromContact(contactId, selectedTag.name, clientId);
        trackTagRemoved(selectedTag._id, selectedTag.name);

        inputRef?.current?.focus();
      } catch (error) {
        toast.error('Failed to remove tag from contact');

        // Undoing the changes
        addOrRemoveContactTag(selectedTag);

        console.error('Failed to remove tag from contact:', error);
      }
    }
  };

  const createTag = async (newTag) => {
    try {
      const response = await addTagToContact(contactId, newTag, clientId);

      const { tag } = response;
      addCustomerTags(tag);
      addContactTag(tag);
    } catch (error) {
      console.error(error);
      toast.error('Failed to create new tag');
      // Handle the error appropriately here
    }
  };

  const handleKeyPress = useCallback(() => {
    trackContactTagButtonClicked('keyboard');
    setTagPopoverOpen(true);
  }, []);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'T',
        global: true,
        group: 'Contact Details',
        label: 'Modify Tags',
        onKeyUp: handleKeyPress,
      },
    ],
    [handleKeyPress]
  );
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  const [tagPopoverOpen, setTagPopoverOpen] = useState(false);

  return (
    <div className='flex flex-col space-y-2 border-b border-borderColor px-4'>
      <div className='flex items-center justify-between'>
        <PlanSelectionModal show={planSelectionModalStatus} />
        <div className='text-sm font-medium'>Contact Tags</div>
        <Popover open={tagPopoverOpen} onOpenChange={setTagPopoverOpen}>
          <TooltipComponent
            content={
              <div className='flex space-x-2'>
                <div>Assign Tags</div>
                <kbd className='pointer-events-none  hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
                  T
                </kbd>
              </div>
            }
          >
            <PopoverTrigger
              asChild
              tabIndex={0}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
            >
              <Button
                variant='outline'
                size='xs'
                className='space-x-2 bg-primary text-xs text-primary-foreground'
                onClick={() => trackContactTagButtonClicked('mouse')}
              >
                <PlusCircle className='mr-2 h-4 w-4' />
                Add Tags
              </Button>
            </PopoverTrigger>
          </TooltipComponent>

          <PopoverContent
            side='left'
            align='start'
            className='w-[200px] p-0'
            onCloseAutoFocus={(e) => e.preventDefault()}
          >
            <Command loop>
              <CommandInput
                ref={inputRef}
                placeholder='Search tags...'
                value={inputValue}
                onValueChange={setInputValue}
              />
              <CommandGroup className='max-h-[145px] overflow-auto'>
                {customerTags
                  .filter(
                    (item) =>
                      !otherTagConditions.some(
                        (otherTagItem) => otherTagItem._id === item._id
                      )
                  )
                  .map((tag) => {
                    const isActive = contactTags.some(
                      (item) => item._id === tag._id
                    );
                    return (
                      <CommandItem
                        key={tag._id}
                        value={tag.name}
                        onSelect={() => toggleTag(tag)}
                        className='cursor-pointer'
                      >
                        <Check
                          className={cn(
                            'mr-2 h-4 w-4',
                            isActive ? 'opacity-100' : 'opacity-0'
                          )}
                        />
                        <div className='flex-1'>{tag.name}</div>
                        {/* <div
                      className='h-4 w-4 rounded-full'
                      style={{ backgroundColor: framework.color }}
                    /> */}
                      </CommandItem>
                    );
                  })}
                <CommandItemCreate
                  onSelect={() => createTag(inputValue)}
                  {...{ inputValue, customerTags }}
                />
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
      {/* <FancyBox /> */}
      <div className='pb-3'>
        {contactTags.map((item) => (
          <Badge
            key={item._id}
            className='mb-2 mr-2 text-sm'
            onClick={() => toggleTag(item)}
          >
            {item.name}
            <Cross2Icon className='ml-2 h-3 w-3' />
          </Badge>
        ))}
      </div>
    </div>
  );
};

export default ContactTags;

const CommandItemCreate = ({ inputValue, customerTags, onSelect }) => {
  const hasNoCustomerTag = !customerTags.some(
    (tag) => tag.name.toLowerCase() === `${inputValue.toLowerCase()}`
  );

  const render = inputValue !== '' && hasNoCustomerTag;

  if (!render) return null;

  // BUG: whenever a space is appended, the Create-Button will not be shown.
  return (
    <CommandItem
      key={`${inputValue}`}
      value={`${inputValue}`}
      className='text-xs text-muted-foreground'
      onSelect={onSelect}
    >
      <div className={cn('mr-2 h-4 w-4')} />
      Create new label &quot;{inputValue}&quot;
    </CommandItem>
  );
};

const PlanSelectionModal = ({ show }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id: contactId } = useParams();

  const { plans, setPlanSelectionModalStatus } = usePlanStore(
    useShallow((state) => ({
      plans: state.plans,
      setPlanSelectionModalStatus: state.setPlanSelectionModalStatus,
    }))
  );

  const { customerTags, primaryTag } = useInboxStore(
    useShallow((state) => ({
      customerTags: state.tags,
      primaryTag: state.primaryTag,
    }))
  );

  const { addOrRemoveContactTag, setPlan } = useContactStore(
    useShallow((state) => ({
      addOrRemoveContactTag: state.addOrRemoveContactTag,
      setPlan: state.setPlan,
    }))
  );

  const clientId = useUserStore((state) => state.clientId);

  const CancelPlanSelection = (status) => {
    if (!status) {
      toast.warning(
        `Plan selection cancelled. Please note, a plan is required to add the "${primaryTag}" tag to this contact.`
      );

      setPlanSelectionModalStatus(false);
    }
  };

  const onSubmitSelectedPlan = async () => {
    try {
      setIsLoading(true);
      // Attach Plan to Contact
      const attachPlanResponse = await attachPlanToContact(
        contactId,
        selectedPlan,
        clientId
      );

      //Update plan in the contact store
      setPlan(attachPlanResponse?.contact?.plan);

      const selectedTag = customerTags.find((tag) => tag.name === primaryTag);

      // Add tag to the contact store
      addOrRemoveContactTag(selectedTag);

      // call API to add tag to contact
      await addTagToContact(contactId, selectedTag.name, clientId);
      setIsLoading(false);
      setPlanSelectionModalStatus(false);
    } catch (error) {
      toast.error('Failed to add plan to contact');
      console.error('Failed to add plan to contact:', error);
    }
  };

  return (
    <Dialog open={show} onOpenChange={CancelPlanSelection}>
      {/* <DialogTrigger asChild>
        <Button variant='outline'>Edit Profile</Button>
      </DialogTrigger> */}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Great Win!</DialogTitle>
          <DialogDescription>
            Please select a plan to assign to this contact
          </DialogDescription>
        </DialogHeader>
        <div>
          {/* <div className='grid grid-cols-4 items-center gap-4'> */}
          {/* <Label className='text-right'>Plan:</Label> */}
          <Select onValueChange={setSelectedPlan}>
            <SelectTrigger>
              <SelectValue placeholder='Select a plan' />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {plans.map((plan) => (
                  <SelectItem key={plan._id} value={plan._id}>
                    {plan.name} - ${plan.amount}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          {/* </div> */}
        </div>
        <DialogFooter>
          {isLoading ? (
            <ButtonLoading />
          ) : (
            <Button type='submit' onClick={onSubmitSelectedPlan}>
              Save
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
