import { useCallback, useMemo, useState } from 'react';
import useContactStore from '@/stores/ContactStore';
import useInboxStore from '@/stores/InboxStore';
import useUserStore from '@/stores/UserStore';
import { useHotkeys } from '@blueprintjs/core';
import {
  ArchiveRestoreIcon,
  CheckIcon,
  Loader2Icon,
  UserIcon,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import {
  trackContactStatusButtonClicked,
  trackContactStatusChange,
  trackUserProfileButtonClicked,
} from '@/lib/analytics-event';
import { updateContactStatus } from '@/hooks/contact';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import KeyboardShortcut from '@/components/common/keyboard/keyboard';
import TooltipComponent from '@/components/common/tooltip/tooltip';

export default function ContactHead() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const setContactSidebarShow = useInboxStore(
    (state) => state.setContactSidebarShow
  );
  const clientId = useUserStore((state) => state.clientId);
  const {
    contactSidebarShow,
    removeConversation,
    decreaseConversationCount,
    increaseConversationCount,
    selectedConversationStatus,
  } = useInboxStore(
    useShallow((state) => ({
      contactSidebarShow: state.contactSidebarShow,
      removeConversation: state.removeConversation,
      decreaseConversationCount: state.decreaseConversationCount,
      increaseConversationCount: state.increaseConversationCount,
      selectedConversationStatus: state.selectedConversationStatus,
    }))
  );

  const { id, fullname, status, profile_picture_url, setContactStatus } =
    useContactStore(
      useShallow((state) => ({
        id: state._id,
        fullname: state.fullname,
        status: state.status,
        profile_picture_url: state.profile_picture_url,
        setContactStatus: state.setContactStatus,
      }))
    );

  const updateStatus = useCallback(
    async (mode) => {
      try {
        setLoading(true);
        const newStatus = status === 'open' ? 'close' : 'open';
        trackContactStatusButtonClicked(mode);

        const response = await updateContactStatus(id, newStatus, clientId);
        setContactStatus(newStatus);
        trackContactStatusChange(newStatus);

        if (selectedConversationStatus.value !== 'all') {
          switch (selectedConversationStatus.value) {
            // If the contact status is changed to the selectedConversationStatus
            case newStatus:
              console.log('insert conversation triggered', id);
              // addConversationToTop(contactData);
              increaseConversationCount();
              break;

            // If the contact status is changed to the opposite of selectedConversationStatus
            default:
              console.log('remove conversation triggered', id);
              removeConversation(id);
              decreaseConversationCount();
              navigate('/inbox', {
                replace: true,
              });
              break;
          }
        }
      } catch (error) {
        toast.error('Unable to update contact status');
      }
      setLoading(false);
    },
    [
      id,
      status,
      setContactStatus,
      removeConversation,
      decreaseConversationCount,
      increaseConversationCount,
      selectedConversationStatus,
      navigate,
      clientId,
    ]
  );

  const handleProfileKeyPress = useCallback(() => {
    trackUserProfileButtonClicked(contactSidebarShow, 'keyboard');
    setContactSidebarShow(!contactSidebarShow);
  }, [contactSidebarShow, setContactSidebarShow]);

  const handleUpdateStatusKeyPress = useCallback(() => {
    updateStatus('keyboard');
  }, [updateStatus]);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'P',
        global: true,
        group: 'Contact Details',
        // disabled: !contactSidebarShow,
        label: 'Open Profile',
        onKeyUp: handleProfileKeyPress,
      },
      {
        combo: 'Alt + A',
        global: true,
        group: 'Contact Details',
        disabled: status === 'close',
        label: 'Set Status - Archive',
        onKeyUp: handleUpdateStatusKeyPress,
      },
      {
        combo: 'Alt + O',
        global: true,
        group: 'Contact Details',
        disabled: status === 'open',
        label: 'Set Status - Open',
        onKeyUp: handleUpdateStatusKeyPress,
      },
    ],
    [handleProfileKeyPress, handleUpdateStatusKeyPress, status]
  );
  const hotkey = useHotkeys(hotkeys);

  return (
    <>
      {id && (
        <div className='mx-5 my-3 flex h-14  flex-grow-0 items-center justify-between'>
          <div className='flex cursor-default items-center justify-center space-x-4'>
            <Avatar>
              <AvatarImage
                src={
                  profile_picture_url
                    ? profile_picture_url
                    : 'https://files.sbccrm.com/sbccrm/sbccrm/contacts/pics/no-pic.jpg'
                }
              />
              <AvatarFallback className='bg-primary-foreground text-primary'>
                {fullname[0]}
              </AvatarFallback>
            </Avatar>
            <span className='font-medium'>{fullname}</span>
          </div>
          <div className='flex items-center justify-around space-x-5'>
            {/* Profile Icon*/}
            <div>
              <TooltipComponent
                content={
                  <div className='flex space-x-2'>
                    <div>Profile</div>

                    <kbd className='pointer-events-none  hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
                      p
                    </kbd>
                  </div>
                }
              >
                <Button
                  size='xs'
                  variant='ghost'
                  onClick={() =>
                    trackUserProfileButtonClicked(contactSidebarShow, 'mouse')
                  }
                >
                  <UserIcon onClick={setContactSidebarShow} />
                </Button>
              </TooltipComponent>
            </div>
            {/* Archive or Open Actions*/}
            <div>
              {loading ? (
                <Button size='xs' variant='ghost'>
                  <Loader2Icon className='animate-spin' />
                </Button>
              ) : (
                <>
                  <TooltipComponent
                    content={
                      <div className='flex space-x-2'>
                        {status === 'open' ? (
                          <>
                            <div>Archive</div>
                            <KeyboardShortcut keys={['Atl', 'A']} />
                          </>
                        ) : (
                          <>
                            <div>Open</div>
                            <KeyboardShortcut keys={['Atl', 'O']} />
                          </>
                        )}
                      </div>
                    }
                  >
                    <Button
                      size='xs'
                      variant='ghost'
                      onClick={() => updateStatus('mouse')}
                    >
                      {status === 'open' ? (
                        <CheckIcon />
                      ) : (
                        <ArchiveRestoreIcon />
                      )}
                    </Button>
                  </TooltipComponent>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <Separator orientation={'horizontal'} />
    </>
  );
}
