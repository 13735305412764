import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = {
  crmContactList: [],

  crmContactSearchFilter: [],
};

const useCrmContactStore = create(
  devtools(
    (set) => ({
      ...initialState,

      setCrmContactList: (crmContactList) =>
        set({
          crmContactList,
        }),

      setCrmContactSearchFilter: (crmContactSearchFilter) =>
        set({
          crmContactSearchFilter,
        }),
    }),

    { name: 'CrmContactStore' }
  )
);

export default useCrmContactStore;
