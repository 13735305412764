import { useCallback, useMemo, useRef, useState } from 'react';
import useInboxStore from '@/stores/InboxStore';
import { useHotkeys } from '@blueprintjs/core';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useDebouncedCallback } from 'use-debounce';
import { useShallow } from 'zustand/react/shallow';

import {
  trackSearchTextClearButtonClicked,
  trackSearchTextInputClicked,
} from '@/lib/analytics-event';

import { Button } from '../ui/button';
import { Input } from '../ui/input';

const ConversationSearch = () => {
  const [inputText, setInputText] = useState('');
  const searchInputRef = useRef(null);

  const { setSearchText } = useInboxStore(
    useShallow((state) => ({
      setSearchText: state.setSearchText,
    }))
  );

  const handleDebouncedInput = useDebouncedCallback((value) => {
    setSearchText(value);
  }, 500);

  const handleSearch = useCallback(
    (value) => {
      setInputText(value);
      handleDebouncedInput(value);
    },
    [handleDebouncedInput]
  );

  const handleSearchClear = useCallback(
    (mode) => {
      handleSearch('');
      trackSearchTextClearButtonClicked(mode);
    },
    [handleSearch]
  );

  const handleKeyPress = useCallback(() => {
    searchInputRef?.current.focus();
    trackSearchTextInputClicked('keyboard');
  }, []);

  const hotkeys = useMemo(
    () => [
      {
        combo: '/',
        global: true,
        group: 'Filtering',
        // disabled: !contactSidebarShow,
        label: 'Search Messages or Users',
        onKeyUp: handleKeyPress,
      },
      {
        combo: 'shift+c',
        global: true,
        group: 'Filtering',
        disabled: !inputText,
        label: 'Clear Search text',
        onKeyDown: () => handleSearchClear('keyboard'),
      },
    ],
    [handleKeyPress, handleSearchClear, inputText]
  );
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  const handleEscapeKeyPress = useCallback((e) => {
    if (e.key === 'Escape') {
      searchInputRef?.current.blur();
    }
  }, []);

  return (
    <div className='relative' onKeyUp={handleKeyUp} onKeyDown={handleKeyDown}>
      <Input
        ref={searchInputRef}
        type='text'
        placeholder='Search messages or users'
        value={inputText}
        className='pr-10'
        onChange={(e) => handleSearch(e.target.value)}
        onKeyDown={handleEscapeKeyPress}
        onMouseDown={() => trackSearchTextInputClicked('mouse')}
      />
      {!inputText && (
        <kbd className='pointer-events-none absolute right-3 top-2  hidden h-6 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[12px] font-medium opacity-100 sm:flex'>
          /
        </kbd>
      )}
      {inputText && (
        <Button
          variant='ghost'
          size='icon'
          className='absolute right-1 top-1 h-8 w-8 rounded-full'
          onClick={() => handleSearchClear('mouse')}
        >
          <Cross2Icon />
        </Button>
      )}
    </div>
  );
};

export default ConversationSearch;
