import React from 'react';
import { CheckCheckIcon } from 'lucide-react';

import { cn } from '@/lib/utils';
import TooltipComponent from '@/components/common/tooltip/tooltip';

export default function TextMessage({
  emoji,
  is_seen,
  direction,
  text,
  timestamp,
}) {
  //   console.log(props);
  return (
    <div
      className={cn(
        'rounded-lg px-3 py-2  text-left text-sm',
        direction === 'sent'
          ? 'bg-muted'
          : 'bg-received text-primary-foreground'
      )}
    >
      <p className=''>{text}</p>
      {is_seen && (
        <span className='absolute -bottom-2 right-0'>
          <CheckCheckIcon className='h-4 w-4 text-seen' />
        </span>
      )}
      {emoji && (
        <span
          className={cn(
            'absolute -bottom-3 rounded-full border bg-primary-foreground px-[2px] text-xs',
            direction !== 'sent' ? 'left-2' : is_seen ? 'right-5' : 'right-2'
          )}
        >
          {emoji}
        </span>
      )}
    </div>
  );
}
