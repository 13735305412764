import { useState } from 'react';
import useCrmContactStore from '@/stores/CrmContactStore';
import { PlusCircledIcon } from '@radix-ui/react-icons';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '../ui/button';
import { ScrollArea } from '../ui/scroll-area';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../ui/sheet';
import FilterCard from './crm-contact-filter-sheet/FilterCard';

export default function ContactFilterSheet() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tempAdvancedFilters, setTempAdvancedFilters] = useState([]);

  const { setCrmContactSearchFilter, crmContactSearchFilter } =
    useCrmContactStore(
      useShallow((state) => ({
        crmContactSearchFilter: state.crmContactSearchFilter,
        setCrmContactSearchFilter: state.setCrmContactSearchFilter,
      }))
    );

  const totalAdvancedFiltersCount = crmContactSearchFilter.reduce(
    (acc, item) => acc + item.length,
    0
  );

  // TODO: Below code is for reference, remove the below code before the last commit
  // const [tempAdvancedFilters, setTempAdvancedFilters] = useState([
  //   [
  //     {
  //       field: 'full_name',
  //       operator: 'contains_exactly',
  //       value: 'Amirth',
  //       // type -> text / number / enum / boolean
  //     },
  //     {
  //       field: 'ig_username',
  //       operator: 'contains',
  //       value: 'amir',
  //     },
  //   ],
  //   [
  //     {
  //       field: 'is_verified',
  //       operator: 'is',
  //       value: 'true',
  //     },
  //     {
  //       field: 'tag',
  //       operator: 'is_one_of',
  //       value: [],
  //     },
  //   ],
  // ]);

  // Function to close the sheet
  const handleCancel = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  // Function to open the sheet and create a copy of the existing filters
  const handleOpen = () => {
    setIsFilterOpen(!isFilterOpen);
    setTempAdvancedFilters(crmContactSearchFilter);
  };

  // Function to validate the selected filters before saving
  const validateFilters = () => {
    return tempAdvancedFilters.every((group) =>
      group.every((filter) => {
        if (['tag_ids', 'plan', 'user_id'].includes(filter.field)) {
          return Array.isArray(filter.value) && filter.value.length > 0;
        } else {
          return typeof filter.value === 'string' && filter.value.trim() !== '';
        }
      })
    );
  };

  // Function to save the filters
  const handleSave = () => {
    if (validateFilters()) {
      setCrmContactSearchFilter(tempAdvancedFilters);
    } else {
      toast.error('Update the conditions properly !');
    }

    setIsFilterOpen(!isFilterOpen);
  };

  // Function to add a new filter
  const handleNewFilter = () => {
    setTempAdvancedFilters([
      [
        {
          field: '',
          operator: '',
          value: '',
        },
      ],
    ]);
  };

  // Function to add a new AND Condition
  const handleANDCondition = (outerIndex) => {
    setTempAdvancedFilters((prevState) => {
      const newState = [...prevState];

      // Add the new filter to the inner array at the specified index
      newState[outerIndex] = [
        ...newState[outerIndex],
        {
          field: '',
          operator: '',
          value: '',
        },
      ];

      return newState;
    });
  };

  // Function to add a new OR Condition
  const handleORCondition = () => {
    setTempAdvancedFilters([
      ...tempAdvancedFilters,
      [
        {
          field: '',
          operator: '',
          value: '',
        },
      ],
    ]);
  };

  return (
    <Sheet open={isFilterOpen} onOpenChange={handleCancel}>
      <SheetTrigger asChild>
        <Button onClick={handleOpen}>
          Advanced Filter ({totalAdvancedFiltersCount})
        </Button>
      </SheetTrigger>
      <SheetContent className='flex w-[400px] flex-col sm:w-[540px] sm:max-w-md'>
        <SheetHeader>
          <SheetTitle>Advanced Filters</SheetTitle>
        </SheetHeader>
        <div className='flex overflow-y-auto'>
          <ScrollArea className='w-full'>
            {tempAdvancedFilters.length > 0 ? (
              <div className='w-full'>
                {tempAdvancedFilters.map((outerFilter, outerIndex) => (
                  <div key={outerIndex}>
                    <div className='w-full rounded-md border p-2'>
                      {outerFilter.map((condition, innerIndex) => (
                        <div key={`${outerIndex}_${innerIndex}`}>
                          <div>
                            <div key={innerIndex}>
                              <FilterCard
                                setTempAdvancedFilters={setTempAdvancedFilters}
                                outerIndex={outerIndex}
                                innerIndex={innerIndex}
                                s
                                propertyField={condition.field}
                                propertyOperator={condition.operator}
                                propertyValue={condition.value}
                              />
                            </div>
                          </div>
                          {innerIndex !==
                            tempAdvancedFilters[outerIndex].length - 1 && (
                            <div className='relative ml-4 py-2 text-xs text-foreground'>
                              <span className='absolute inset-0 left-4 h-full w-px bg-border'></span>
                              <span className='relative rounded-md border bg-background px-1'>
                                and
                              </span>
                            </div>
                          )}
                          {innerIndex ===
                            tempAdvancedFilters[outerIndex].length - 1 && (
                            <Button
                              variant='secondary'
                              size='xs'
                              className='mt-3'
                              onClick={() => handleANDCondition(outerIndex)}
                            >
                              AND
                            </Button>
                          )}
                        </div>
                      ))}
                    </div>
                    {outerIndex !== tempAdvancedFilters.length - 1 && (
                      <div className='relative ml-5 py-2 text-xs text-foreground'>
                        <span className='absolute inset-0 left-3 h-full w-px bg-border'></span>
                        <span className='relative rounded-md border bg-background px-1'>
                          or
                        </span>
                      </div>
                    )}
                  </div>
                ))}
                <Button
                  variant='secondary'
                  size='xs'
                  className='mt-3'
                  onClick={handleORCondition}
                >
                  OR
                </Button>
              </div>
            ) : (
              <Button className='m-auto' onClick={handleNewFilter}>
                <PlusCircledIcon className='mr-2 h-4 w-4' /> Add Filter
              </Button>
            )}
          </ScrollArea>
        </div>
        <SheetFooter className='mt-auto flex justify-end'>
          <SheetClose asChild>
            <Button variant='outline'>Cancel</Button>
          </SheetClose>
          <Button onClick={handleSave}>Save</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
