import { useCallback, useMemo, useState } from 'react';
import useInboxStore from '@/stores/InboxStore';
import { useHotkeys } from '@blueprintjs/core';

import { trackStatusFilterButtonClicked } from '@/lib/analytics-event';

import TooltipComponent from '../common/tooltip/tooltip';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

const ConversationStatusPicker = () => {
  const selectedConversationStatus = useInboxStore(
    (state) => state.selectedConversationStatus
  );
  const conversationCount = useInboxStore((state) => state.conversationCount);
  const setConversationStatus = useInboxStore(
    (state) => state.setConversationStatus
  );

  const conversationStates = [
    { value: 'open', label: 'Open' },
    { value: 'close', label: 'Archived' },
    { value: 'all', label: 'All' },
  ];

  const handleKeyPress = useCallback(() => {
    setConversationStatusDropdownOpen(true);
    trackStatusFilterButtonClicked('keyboard');
  }, []);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'shift + S',
        global: true,
        group: 'Filtering',
        // disabled: !contactSidebarShow,
        label: 'Select Status',
        onKeyUp: handleKeyPress,
      },
    ],
    [handleKeyPress]
  );
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  const [conversationStatusDropdownOpen, setConversationStatusDropdownOpen] =
    useState(false);

  return (
    <>
      <DropdownMenu
        open={conversationStatusDropdownOpen}
        onOpenChange={setConversationStatusDropdownOpen}
      >
        <TooltipComponent
          content={
            <div className='flex space-x-2'>
              <div>Filter Status</div>
              <div className='flex space-x-1'>
                <kbd className='pointer-events-none  hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
                  shift
                </kbd>
                <kbd className='pointer-events-none  hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
                  S
                </kbd>
              </div>
            </div>
          }
        >
          <DropdownMenuTrigger
            asChild
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
          >
            <Button
              size='sm'
              className='cursor-pointer select-none rounded-2xl ring-0'
            >
              {selectedConversationStatus.label} ({conversationCount})
            </Button>
          </DropdownMenuTrigger>
        </TooltipComponent>
        <DropdownMenuContent
          align='start'
          onCloseAutoFocus={(e) => e.preventDefault()}
        >
          {conversationStates.map((item) => (
            <DropdownMenuItem
              key={item.value}
              onSelect={() => setConversationStatus(item)}
              className='cursor-pointer'
            >
              {item.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default ConversationStatusPicker;
