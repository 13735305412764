import { useEffect, useState } from 'react';
import useInboxStore from '@/stores/InboxStore';
import usePlanStore from '@/stores/PlanStore';
import { XIcon } from 'lucide-react';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import FilterValuePopover from './FilterValuePopover';

// List of all the supported fields
const fieldList = [
  {
    _id: 1,
    value: 'full_name',
    label: 'Full Name',
    type: 'text',
  },
  {
    _id: 2,
    value: 'ig_username',
    label: 'IG Username',
    type: 'text',
  },
  {
    _id: 3,
    value: 'bio',
    label: 'Bio',
    type: 'text',
  },
  {
    _id: 4,
    value: 'verified',
    label: 'Is Verified',
    type: 'boolean',
  },
  {
    _id: 5,
    value: 'is_business_account',
    label: 'Is Business Account',
    type: 'boolean',
  },
  {
    _id: 6,
    value: 'profile_picture_url',
    label: 'Profile Picture',
    type: 'boolean',
  },
  {
    _id: 7,
    value: 'post_count',
    label: 'Post Count',
    type: 'number',
  },
  {
    _id: 8,
    value: 'followers_count',
    label: 'Followers Count',
    type: 'number',
  },
  {
    _id: 9,
    value: 'following_count',
    label: 'Following Count',
    type: 'number',
  },
  {
    _id: 10,
    value: 'tag_ids',
    label: 'Tag',
    type: 'enum',
  },
  {
    _id: 11,
    value: 'plan',
    label: 'Plan',
    type: 'enum',
  },
  {
    _id: 12,
    value: 'user_id',
    label: 'Contact Owner',
    type: 'enum',
  },
];

// List of all the supported operators
const operatorList = [
  {
    _id: 1,
    type: 'enum',
    value: 'is_one_of',
    label: 'is one of',
  },
  {
    _id: 2,
    type: 'enum',
    value: 'is_none_of',
    label: 'is none of',
  },
  {
    _id: 3,
    type: 'text',
    value: 'contains',
    label: 'contains',
  },
  {
    _id: 4,
    type: 'text',
    value: 'contains_exactly',
    label: 'contains exactly',
  },
  {
    _id: 5,
    type: 'text',
    value: 'does_not_contain',
    label: 'does not contain',
  },
  {
    _id: 6,
    type: 'boolean',
    value: 'is',
    label: 'is',
  },
  {
    _id: 7,
    type: 'boolean',
    value: 'is_not',
    label: 'is not',
  },
  {
    _id: 8,
    type: 'number',
    value: 'equals',
    label: 'equals',
  },
  {
    _id: 9,
    type: 'number',
    value: 'less_than',
    label: 'less than',
  },
  {
    _id: 10,
    type: 'number',
    value: 'greater_than',
    label: 'greater than',
  },
  {
    _id: 11,
    type: 'number',
    value: 'not_equals',
    label: 'not equals',
  },
];

// List of all the supported boolean values
const booleanList = [
  {
    _id: 1,
    value: 'true',
    label: 'TRUE',
  },
  {
    _id: 2,
    value: 'false',
    label: 'FALSE',
  },
];

const FilterCard = ({
  setTempAdvancedFilters,
  outerIndex,
  innerIndex,
  propertyField,
  propertyOperator,
  propertyValue,
}) => {
  const [selectedField, setSelectedField] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('');
  const [selectedOperatorOptions, setSelectedOperatorOptions] = useState('');
  const [popoverFilterValues, setPopoverFilterValues] = useState([]);

  const { tags, owners } = useInboxStore(
    useShallow((state) => ({
      tags: state.tags,
      owners: state.owners,
    }))
  );
  const plans = usePlanStore((state) => state.plans);

  useEffect(() => {
    // Set initial selected values based on props
    setSelectedField(propertyField);

    if (propertyOperator) {
      const selectedOperatorType = operatorList.find(
        (operator) => operator.value === propertyOperator
      );
      if (selectedOperatorType) {
        const selectedOperators = operatorList.filter(
          (operator) => operator.type === selectedOperatorType.type
        );
        if (selectedOperators.length > 0) {
          setSelectedOperator(selectedOperatorType.type);
          setSelectedOperatorOptions(selectedOperators);
          updatePopoverItems(propertyField, selectedOperatorType.type);
        } else {
          setSelectedOperator('');
          setSelectedOperatorOptions('');
        }
      }
    }
  }, []);

  // Function to update the field values  for popover items
  const updatePopoverItems = (popoverConditionField, selectedOperatorType) => {
    console.log(popoverConditionField, selectedOperatorType);
    if (selectedOperatorType === 'enum') {
      if (popoverConditionField === 'tag_ids') {
        setPopoverFilterValues(tags);
      } else if (popoverConditionField === 'user_id') {
        setPopoverFilterValues(owners);
      } else if (popoverConditionField === 'plan') {
        setPopoverFilterValues(plans);
      }
    }
  };
  // Function to update the field value in the temp filters
  const updateFilterField = (newField) => {
    const selectedFieldInfo = fieldList.find(
      (field) => field.value === newField
    );

    setTempAdvancedFilters((prevState) => {
      // Create a copy of the state array
      const newState = [...prevState];

      // Modify the field value of the filter at the specified innerIndex in the inner array at the specified outerIndex
      newState[outerIndex][innerIndex] = {
        field: newField,
        operator: '',
        value: selectedFieldInfo.type === 'enum' ? [] : '',
      };

      return newState;
    });

    setSelectedField(newField);

    if (selectedFieldInfo) {
      const selectedOperators = operatorList.filter(
        (operator) => operator.type === selectedFieldInfo.type
      );
      if (selectedOperators.length > 0) {
        setSelectedOperatorOptions(selectedOperators);
        setSelectedOperator('');
      } else {
        setSelectedOperator('');
        setSelectedOperatorOptions('');
      }
    }
  };

  // Function to update the operator value in the temp filters
  const updateFilterOperator = (newOperator) => {
    const selectedOperatorType = operatorList.find(
      (operator) => operator.value === newOperator
    );

    setTempAdvancedFilters((prevState) => {
      // Create a copy of the state array
      const newState = [...prevState];

      // Modify the field value of the filter at the specified innerIndex in the inner array at the specified outerIndex
      newState[outerIndex][innerIndex] = {
        ...newState[outerIndex][innerIndex],
        operator: newOperator,
        value: selectedOperatorType.type === 'enum' ? [] : '',
      };

      return newState;
    });

    if (selectedOperatorType) {
      setSelectedOperator(selectedOperatorType.type);
      updatePopoverItems(selectedField, selectedOperatorType.type);
    } else {
      setSelectedOperator('');
    }
  };

  // Function to update the value in the temp filters
  const updateFilterValue = (newValue) => {
    setTempAdvancedFilters((prevState) => {
      // Create a copy of the state array
      const newState = [...prevState];

      // Modify the field value of the filter at the specified innerIndex in the inner array at the specified outerIndex
      newState[outerIndex][innerIndex] = {
        ...newState[outerIndex][innerIndex],
        value: newValue,
      };

      return newState;
    });
  };

  // Function to update the value for boolean input in the temp filters
  const handleBoolenSelect = (newValue) => {
    setTempAdvancedFilters((prevState) => {
      // Create a copy of the state array
      const newState = [...prevState];

      // Modify the field value of the filter at the specified innerIndex in the inner array at the specified outerIndex
      newState[outerIndex][innerIndex] = {
        ...newState[outerIndex][innerIndex],
        value: newValue,
      };

      return newState;
    });
  };

  // Function to update the value for text/number input in the temp filters
  const handleInputChange = (newValue) => {
    setTempAdvancedFilters((prevState) => {
      // Create a copy of the state array
      const newState = [...prevState];

      // Modify the field value of the filter at the specified innerIndex in the inner array at the specified outerIndex
      newState[outerIndex][innerIndex] = {
        ...newState[outerIndex][innerIndex],
        value: newValue,
      };

      return newState;
    });
  };

  // Function to delete the filters from the temp filters
  const handleFilterDelete = () => {
    setTempAdvancedFilters((prevState) => {
      // Create a copy of the state array
      const newState = [...prevState];

      newState[outerIndex] = newState[outerIndex].filter(
        (_, index) => index !== innerIndex
      );

      // If the inner array becomes empty, remove the outer array
      if (newState[outerIndex].length === 0) {
        newState.splice(outerIndex, 1);
      }

      return newState;
    });
  };

  return (
    <div className='flex cursor-default flex-row space-x-2 rounded-md border p-3 py-3 hover:bg-foreground/10'>
      <div className='flex flex-grow flex-col'>
        <div className='w-full'>
          <Select value={selectedField} onValueChange={updateFilterField}>
            <SelectTrigger className=''>
              <SelectValue placeholder='Select property' />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {fieldList.map((field) => (
                  <SelectItem key={field._id} value={field.value}>
                    {field.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className='mt-2 flex flex-shrink flex-row space-x-1'>
          <div className='w-1/2'>
            <Select
              value={propertyOperator}
              onValueChange={updateFilterOperator}
              disabled={!propertyField}
            >
              <SelectTrigger className=''>
                <SelectValue placeholder='Select operator' />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {selectedOperatorOptions &&
                    selectedOperatorOptions.map((operator) => (
                      <SelectItem key={operator._id} value={operator.value}>
                        {operator.label}
                      </SelectItem>
                    ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className='w-1/2'>
            {selectedOperator === 'text' || selectedOperator === 'number' ? (
              <Input
                type={selectedOperator === 'text' ? 'text' : 'number'}
                placeholder='Value'
                onChange={(e) => handleInputChange(e.target.value)}
                value={propertyValue}
              />
            ) : selectedOperator === 'boolean' ? (
              <Select value={propertyValue} onValueChange={handleBoolenSelect}>
                <SelectTrigger>
                  <SelectValue placeholder='Select Value' />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {booleanList.map((option) => (
                      <SelectItem key={option._id} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            ) : selectedOperator === 'enum' ? (
              <FilterValuePopover
                filterValues={popoverFilterValues}
                updateFilterValue={updateFilterValue}
                selectedFilterValues={propertyValue}
                filterFieldName={propertyField}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className='flex items-center justify-center'>
        <Button variant='ghost' size='xs' onClick={handleFilterDelete}>
          <XIcon className='h-3 w-3' />
        </Button>
      </div>
    </div>
  );
};

export default FilterCard;
