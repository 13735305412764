import { InfoCircledIcon } from '@radix-ui/react-icons';
import { CheckCheckIcon } from 'lucide-react';

import { cn } from '@/lib/utils';

export default function CustomMessage({
  direction,
  is_seen,
  emoji,
  customMessage,
}) {
  return (
    <div
      className={cn(
        'rounded-lg px-3 py-2  text-left text-sm',
        direction === 'sent' ? 'bg-muted' : 'bg-received text-primary-foreground'
      )}
    >
      <p className='flex items-center justify-center space-x-2'>
        <InfoCircledIcon />
        <span>{customMessage}</span>
      </p>
      {is_seen && (
        <span className='absolute -bottom-2 right-0'>
          <CheckCheckIcon className='h-4 w-4 text-seen' />
        </span>
      )}
      {emoji && (
        <span
          className={cn(
            'absolute -bottom-3 rounded-full border bg-primary-foreground px-[2px] text-xs',
            direction !== 'sent' ? 'left-2' : is_seen ? 'right-5' : 'right-2'
          )}
        >
          {emoji}
        </span>
      )}
    </div>
  );
}
