import { useEffect, useState } from 'react';
import useContactStore from '@/stores/ContactStore';
import useInboxStore from '@/stores/InboxStore';
import usePlanStore from '@/stores/PlanStore';
import useUserStore from '@/stores/UserStore';
import { Outlet, useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  trackUserAuthenticationFailed,
  trackUserAuthenticationSuccess,
} from '@/lib/analytics-event';
import { isUserAuthenticated, removeToken } from '@/lib/utils';
import {
  fetchCustomerPlans,
  fetchCustomerTags,
  fetchOwnerTags,
} from '@/hooks/inbox';
import { fetchUserDetails } from '@/hooks/user';

import AblyManager from '../common/AblyManager';
import { AblyMessages } from '../common/AblyMessages';
import ConnectionBanner from '../common/ConnectionBanner';

// Function to check if a route is auth protected ad if the user is authenticated
export default function AuthLayout() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user, setUser, setCustomer, resetUserStore } = useUserStore(
    useShallow((state) => ({
      user: state.user,
      setUser: state.setUser,
      setCustomer: state.setCustomer,
      resetUserStore: state.resetUserStore,
    }))
  );

  const { setTags, setOwners, resetInboxStore } = useInboxStore(
    useShallow((state) => ({
      setTags: state.setTags,
      setOwners: state.setOwners,
      resetInboxStore: state.resetInboxStore,
    }))
  );

  const resetContactStore = useContactStore((state) => state.resetContactStore);

  const setPlans = usePlanStore((state) => state.setPlans);

  useEffect(() => {
    if (!isUserAuthenticated()) {
      navigate('/login', { replace: true });
    }
    setLoading(false);
  }, [navigate]);

  // Fetch loggedIn user data
  const fetchUserData = async () => {
    try {
      const response = await fetchUserDetails();
      setUser(response);

      setCustomer(response.customer_id);

      trackUserAuthenticationSuccess(response);
    } catch (error) {
      console.error('Error fetching user details:', error);
      if (error === 'Invalid credentials') {
        removeToken();
        // reset user store
        resetUserStore();
        // reset contact store
        resetContactStore();
        // reset inbox store
        resetInboxStore();

        trackUserAuthenticationFailed(error);

        navigate('/login', { replace: true });
      }
    }
  };

  // Fetch customer tags list
  const fetchTags = async () => {
    try {
      const response = await fetchCustomerTags();
      setTags(response.tags);
    } catch (error) {
      console.error('Error fetching customer tags list:', error);
    }
  };

  // Fetch customer owners list
  const fetchOwners = async () => {
    try {
      const response = await fetchOwnerTags();
      setOwners(response);
    } catch (error) {
      console.error('Error fetching customer owners list:', error);
    }
  };

  // Fetch customer plans list
  const fetchPlans = async () => {
    try {
      const response = await fetchCustomerPlans();
      setPlans(response.plans);
    } catch (error) {
      console.error('Error fetching customer plans list:', error);
    }
  };

  useEffect(() => {
    if (isUserAuthenticated() && !user) {
      fetchUserData();
      fetchTags();
      fetchOwners();
      fetchPlans();
    }
  }, []);

  useEffect(() => {
    if (user) {
      fetchTags();
      fetchOwners();
      fetchPlans();
    }
  }, []);

  return loading ? null : (
    <>
      <ConnectionBanner />
      <AblyManager />
      <AblyMessages />
      <Outlet />
    </>
  );
}
