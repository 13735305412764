import {
  agentList,
  conversationList,
  customerTagList,
  planList,
} from '@/constants/endpoints';

import { APIClient } from '@/lib/ApiClient';

const apiClient = new APIClient();

export function fetchConversations(filter, page, status, order) {
  console.log('fetch conversation functions');
  return apiClient.create(
    conversationList,
    {
      filters: filter,
      page: page,
      status: status.value,
      order: order,
    },
    true
  );
}

export function fetchCustomerTags() {
  return apiClient.get(customerTagList, {}, true);
}

export function fetchOwnerTags() {
  return apiClient.get(agentList, {}, true);
}

export function fetchCustomerPlans() {
  return apiClient.get(planList, {}, true);
}
