import { useEffect } from 'react';
import usePlanStore from '@/stores/PlanStore';
import { MoreHorizontal } from 'lucide-react';
import { toast } from 'sonner';

import { deletePlan, fetchPlans } from '@/hooks/plans';

import { DataTable } from '../common/table/data-table';
import { DataTableColumnHeader } from '../common/table/data-table-column-header';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Separator } from '../ui/separator';
import CreateOrEditPlan from './plans/CreateOrEditPlan';
import DeletePlanConfirmationModal from './plans/DeletePlan';

const Plans = () => {
  const columns = [
    {
      accessorKey: 'name',
      id: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Plan Name' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
        </div>
      ),
    },
    {
      accessorKey: 'amount',
      id: 'amount',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Amount' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
        </div>
      ),
    },

    {
      id: 'actions',
      cell: ({ row }) => {
        const item = row.original;

        return (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant='ghost' className='h-8 w-8 p-0'>
                  <span className='sr-only'>Open menu</span>
                  <MoreHorizontal className='h-4 w-4' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align='end'>
                <DropdownMenuItem
                  onClick={() => {
                    usePlanStore.setState({
                      planModalStatus: true,
                      planModalType: 'edit',
                      selectedPlanId: item._id,
                      selectedPlanName: item.name,
                      selectedPlanAmount: item.amount,
                    });
                  }}
                >
                  Edit Plan
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    usePlanStore.setState({
                      deleteModalStatus: true,
                      selectedPlanId: item._id,
                      selectedPlanName: item.name,
                      selectedPlanAmount: item.amount,
                    });
                  }}
                >
                  Delete plan
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        );
      },
    },
  ];

  const plans = usePlanStore((state) => state.plans);
  const setPlans = usePlanStore((state) => state.setPlans);

  useEffect(() => {
    const fetchPlanList = async () => {
      try {
        const planList = await fetchPlans();
        setPlans(planList?.plans);
      } catch (error) {
        console.error('Failed to fetch plans:', error);
      }
    };

    fetchPlanList();
  }, [setPlans]);

  return (
    <div className='space-y-6'>
      <div className='flex justify-between'>
        <div>
          <h3 className='text-lg font-medium'>Plans</h3>
          <p className='text-sm text-muted-foreground'>Manage your plans.</p>
        </div>
        <CreateOrEditPlan />
      </div>
      <Separator />

      <DataTable columns={columns} data={plans} hideTheseColumnsInSm={{}} />

      <DeletePlanConfirmationModal />
    </div>
  );
};

export default Plans;
