import { useState } from 'react';
import useUserStore from '@/stores/UserStore';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';
import { useShallow } from 'zustand/react/shallow';

import { trackLogin, trackLoginFailed } from '@/lib/analytics-event';
import { cn, setToken } from '@/lib/utils';
import { loginUser } from '@/hooks/auth';

import { Alert, AlertDescription } from '../ui/alert';
import { Button } from '../ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';

// Zod form to validate the user form
const formSchema = z.object({
  email: z
    .string({
      required_error: 'email cannot be empty.',
    })
    .trim()
    .email(),
  password: z
    .string({
      required_error: 'password cannot be empty',
    })
    .min(1, { message: 'password cannot be empty' }),
});

export function UserLoginForm({ className, ...props }) {
  const { setUser, setCustomer } = useUserStore(
    useShallow((state) => ({
      setUser: state.setUser,
      setCustomer: state.setCustomer,
    }))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState('');

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  async function onSubmit(values) {
    setIsLoading(true);
    setFormMessage('');
    try {
      const { email, password } = values;
      const response = await loginUser(email, password);

      console.log(response);

      setToken(response.access_token);
      setUser(response.user);
      setCustomer(response.user.customer_id);

      trackLogin(response.user);
    } catch (error) {
      // toast.error(error);
      setFormMessage(`${error}`);
      trackLoginFailed(error);
    }
    setIsLoading(false);
  }

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className='grid gap-3'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='Email'
                      type='email'
                      autoCorrect='off'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='Password'
                      type='password'
                      autoCorrect='off'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button disabled={isLoading} className='mt-2'>
              {isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
              Login
            </Button>
          </div>
          {formMessage && (
            <div className='my-3'>
              <Alert
                variant='destructive'
                className='cursor-default select-none py-2'
              >
                <AlertDescription className='flex items-center justify-center'>
                  {formMessage}
                </AlertDescription>
              </Alert>
            </div>
          )}
        </form>
      </Form>
    </div>
  );
}
