import { useCallback, useMemo, useState } from 'react';
import useInboxStore from '@/stores/InboxStore';
import { useHotkeys } from '@blueprintjs/core';
import { CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { useShallow } from 'zustand/react/shallow';

import { trackOwnerFilterButtonClicked } from '@/lib/analytics-event';
import { cn } from '@/lib/utils';

import TooltipComponent from '../common/tooltip/tooltip';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '../ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { ScrollArea } from '../ui/scroll-area';
import { Separator } from '../ui/separator';

const ConversationAgentPicker = () => {
  const { owners, selectedOwners, setSelectedOwners } = useInboxStore(
    useShallow((state) => ({
      owners: state.owners,
      selectedOwners: state.selectedOwners,
      setSelectedOwners: state.setSelectedOwners,
    }))
  );

  const addItem = (newItem) => {
    setSelectedOwners([...selectedOwners, newItem]);
  };

  const removeItem = (itemToRemove) => {
    const updatedItems = selectedOwners.filter((item) => item !== itemToRemove);
    setSelectedOwners(updatedItems);
  };

  const handleKeyPress = useCallback(() => {
    setFilterOwnersPopoverOpen(true);
    trackOwnerFilterButtonClicked('keyboard');
  }, []);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'shift + O',
        global: true,
        group: 'Filtering',
        // disabled: !contactSidebarShow,
        label: 'Filter Owner',
        onKeyUp: handleKeyPress,
      },
    ],
    [handleKeyPress]
  );
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  const [filterOwnersPopoverOpen, setFilterOwnersPopoverOpen] = useState(false);

  return (
    <Popover
      open={filterOwnersPopoverOpen}
      onOpenChange={setFilterOwnersPopoverOpen}
    >
      <TooltipComponent
        content={
          <div className='flex space-x-2'>
            <div>Filter owner</div>
            <div className='flex space-x-1'>
              <kbd className='pointer-events-none  hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
                shift
              </kbd>
              <kbd className='pointer-events-none  hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
                O
              </kbd>
            </div>
          </div>
        }
      >
        <PopoverTrigger asChild onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
          <Button
            variant='outline'
            size='sm'
            className='h-8 select-none border-dashed'
            onClick={() => trackOwnerFilterButtonClicked('mouse')}
          >
            <PlusCircledIcon className='mr-2 h-4 w-4' />
            Owner
            {selectedOwners?.length > 0 && (
              <>
                <Separator orientation='vertical' className='mx-1 h-4' />
                <Badge
                  variant='secondary'
                  className='rounded-sm px-1 font-normal'
                >
                  {selectedOwners.length}
                </Badge>
              </>
            )}
          </Button>
        </PopoverTrigger>
      </TooltipComponent>

      <PopoverContent
        className='w-[200px] p-0'
        align='start'
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        {owners.length > 0 ? (
          <Command>
            <CommandInput placeholder={'Owner'} />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                <ScrollArea className='h-[250px]'>
                  {owners.map((option) => {
                    const isSelected = selectedOwners.find(
                      (element) => element === option._id
                    );
                    return (
                      <CommandItem
                        key={option._id}
                        onSelect={() => {
                          if (isSelected) {
                            removeItem(option._id);
                          } else {
                            addItem(option._id);
                          }
                        }}
                      >
                        <div
                          className={cn(
                            'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                            isSelected
                              ? 'bg-primary text-primary-foreground'
                              : 'opacity-50 [&_svg]:invisible'
                          )}
                        >
                          <CheckIcon className={cn('h-4 w-4')} />
                        </div>
                        {option.icon && (
                          <option.icon className='mr-2 h-4 w-4 text-muted-foreground' />
                        )}
                        <span>{`${option.first_name} ${option.last_name}`}</span>
                      </CommandItem>
                    );
                  })}
                </ScrollArea>
              </CommandGroup>
              {selectedOwners.length > 0 && (
                <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem
                      onSelect={() => {
                        setSelectedOwners([]);
                      }}
                      className='justify-center text-center'
                    >
                      Clear Filter
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        ) : (
          <div className='py-6 text-center text-sm'> No Owners</div>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default ConversationAgentPicker;
