import React from 'react';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { CheckCheckIcon } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';

export default function ImageMessage({ media_url, direction, is_seen, emoji }) {
  return (
    <>
      <Dialog>
        <DialogTrigger>
          <img
            src={media_url}
            className='max-h-[250px] max-w-[250px] rounded-md'
            alt='image message'
          />
          {is_seen && (
            <span className={'absolute -bottom-2 right-0'}>
              <CheckCheckIcon className='h-4 w-4 text-seen' />
            </span>
          )}
          {emoji && (
            <span
              className={cn(
                'absolute -bottom-3 rounded-full border bg-primary-foreground px-[2px] text-xs',
                direction !== 'sent'
                  ? 'left-3'
                  : is_seen
                    ? 'right-5'
                    : 'right-2'
              )}
            >
              {emoji}
            </span>
          )}
        </DialogTrigger>
        <DialogContent
          className='flex h-screen max-w-full items-center justify-center'
          onCloseAutoFocus={(e) => e.preventDefault()}
        >
          <div className='max-w-screen max-h-screen'>
            <img
              src={media_url}
              className='max-h-[95vh] max-w-full object-contain'
              alt='image message'
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
