import { useEffect, useState } from 'react';
import usePlanStore from '@/stores/PlanStore';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';
import { useShallow } from 'zustand/react/shallow';

import { createPlan, updatePlan } from '@/hooks/plans';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { ButtonLoading } from '@/components/common/button/loading-button';

const FormSchema = z.object({
  planName: z
    .string()
    .min(2, {
      message: 'Name must be at least 2 characters.',
    })
    .max(25, {
      message: 'Name must be less than 25 characters.',
    })
    .trim(),
  amount: z.coerce.number().min(1, {
    message: 'Amount must be at least $1.',
  }),
});

const CreateOrEditPlan = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [
    plans,
    setPlans,
    planModalStatus,
    setPlanModalStatus,
    planModalType,
    setPlanModalType,
    selectedPlanId,
    selectedPlanName,
    selectedPlanAmount,
  ] = usePlanStore(
    useShallow((state) => [
      state.plans,
      state.setPlans,
      state.planModalStatus,
      state.setPlanModalStatus,
      state.planModalType,
      state.setPlanModalType,
      state.selectedPlanId,
      state.selectedPlanName,
      state.selectedPlanAmount,
    ])
  );

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      planName: '',
      amount: 1,
    },
  });

  useEffect(() => {
    if (planModalType === 'edit') {
      form.setValue('planName', selectedPlanName);
      form.setValue('amount', selectedPlanAmount);
    }
  }, [planModalType, selectedPlanName, selectedPlanAmount, form]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const { planName, amount } = data;

      if (planModalType === 'create') {
        const response = await createPlan(planName, amount);

        setPlans([...plans, response.plan]);
        toast.success('Plan created successfully.');
      } else if (planModalType === 'edit') {
        const response = await updatePlan(selectedPlanId, planName, amount);

        const updatedPlans = plans.map((plan) => {
          if (plan._id === selectedPlanId) {
            return response.plan;
          }
          return plan;
        });
        setPlans(updatedPlans);
        toast.success('Plan updated successfully.');
      }
      setIsLoading(false);
      setPlanModalStatus(false);
      form.reset();
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={planModalStatus} onOpenChange={setPlanModalStatus}>
      <DialogTrigger asChild>
        <Button
          onClick={() => {
            setPlanModalType('create');
            form.reset();
          }}
        >
          Add Plan
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          {/* <DialogTitle>Create Plan</DialogTitle> */}
          {planModalType === 'create' ? (
            <>
              <DialogTitle>Create Plan</DialogTitle>
              <DialogDescription>
                Create a new plan by filling in the required fields.
              </DialogDescription>
            </>
          ) : (
            <>
              <DialogTitle>Edit Plan</DialogTitle>
              <DialogDescription>
                Edit plan by filling in the required fields.
              </DialogDescription>
            </>
          )}
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
            <FormField
              control={form.control}
              name='planName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Enter Plan Name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='Plan Name'
                      {...field}
                      autoComplete='off'
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='amount'
              render={({ field }) => (
                <>
                  <FormItem>
                    <FormLabel>Enter Plan Amount</FormLabel>
                    <FormControl>
                      <Input
                        type='number'
                        placeholder={1}
                        {...field}
                        autoComplete='off'
                        className='[&::-webkit-inner-spin-button]:appearance-none'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </>
              )}
            />
            <DialogFooter>
              {isLoading ? (
                <ButtonLoading />
              ) : (
                <Button type='submit'>Save</Button>
              )}
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateOrEditPlan;
