import { useEffect, useState } from 'react';
import useUserStore from '@/stores/UserStore';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { isUserAuthenticated } from '@/lib/utils';

// Function to check if a route is auth protected ad if the user is authenticated
export default function NonAuthLayout() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    if (isUserAuthenticated() || user) {
      navigate('/inbox', { replace: true });
    }
    setLoading(false);
  }, [user, navigate]);

  return loading ? null : <Outlet />;
}
