const KeyboardShortcut = ({ keys }) => (
  <div className='flex space-x-1'>
    {keys.map((key, index) => (
      <kbd
        key={index}
        className='pointer-events-none hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'
      >
        {key}
      </kbd>
    ))}
  </div>
);

export default KeyboardShortcut;
