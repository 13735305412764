import { useEffect, useState } from 'react';
import useCrmContactStore from '@/stores/CrmContactStore';
import useInboxStore from '@/stores/InboxStore';
import { BadgeCheckIcon, Instagram } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { fetchAgents } from '@/hooks/agent';
import { fetchCustomerTagsList } from '@/hooks/contact';
import { getCRMContactList } from '@/hooks/crm-contact';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { DataTableColumnHeader } from '@/components/common/table/data-table-column-header';
import { ManualDataTable } from '@/components/common/table/manual-data-table';

import { CrmContactTableToolbar } from './CrmContactTableToolbar';

const CrmContactsTable = () => {
  const { crmContactList, setCrmContactList, crmContactSearchFilter } =
    useCrmContactStore(
      useShallow((state) => ({
        crmContactList: state.crmContactList,
        setCrmContactList: state.setCrmContactList,

        crmContactSearchFilter: state.crmContactSearchFilter,
      }))
    );

  const [pagination, setPagination] = useState({
    pageSize: 50,
    pageIndex: 0,
  });

  const [lastPageIndex, setLastPageIndex] = useState(0);

  const { tags: tagsList, owners: agentsList } = useInboxStore(
    useShallow((state) => ({
      tags: state.tags,
      owners: state.owners,
    }))
  );

  const fetchCRMContactList = async () => {
    // Resetting CRM Contact List to empty array before fetching new data
    // setCrmContactList([]);

    const filterData = {
      filters: crmContactSearchFilter,
    };

    const page = pagination.pageIndex + 1;
    try {
      const response = await getCRMContactList(page, filterData);
      setCrmContactList(response.data);
      setLastPageIndex(response.last_page);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCRMContactList();
  }, [pagination.pageIndex, crmContactSearchFilter]);
  const columns = [
    {
      accessorKey: 'full_name',
      id: 'full_name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Name' />
      ),
      cell: (record) => (
        <div className='flex items-center space-x-2'>
          {/* <pre>{JSON.stringify(record.row)}</pre> */}
          <Avatar>
            <AvatarImage src={record.row.original.profile_picture_url} />
            <AvatarFallback>CN</AvatarFallback>
          </Avatar>
          <Link
            to={`/inbox/${record.row.original._id}`}
            target='_blank'
            className='-mt-1.5'
          >
            <Button variant='link' size='sm'>
              {record.getValue()}
            </Button>
          </Link>
        </div>
      ),
    },
    {
      accessorKey: 'ig_username',
      id: 'ig_username',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Username' />
      ),
      cell: (record) => (
        <div>
          <Link
            to={`https://www.instagram.com/${record.getValue()}`}
            target='_blank'
          >
            <Button variant='link' size='sm' className='px-0'>
              {record.getValue()}
            </Button>
          </Link>
        </div>
      ),
    },
    {
      accessorKey: 'verified',
      id: 'verified',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Is Verified' />
      ),
      cell: (record) => {
        return (
          <div>
            <div className='mb-1 text-sm font-medium'>
              {record.getValue() === true ? (
                <BadgeCheckIcon className='text-primary' />
              ) : null}
            </div>
          </div>
        );
      },
    },
    // {
    //   accessorKey: 'isBusinessAccount',
    //   id: 'isBusinessAccount',
    //   header: ({ column }) => (
    //     <DataTableColumnHeader column={column} title='Is Business Account' />
    //   ),
    //   cell: (record) => (
    //     <div>
    //       <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
    //     </div>
    //   ),
    // },

    {
      accessorKey: 'tag_ids',
      id: 'tag_ids',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Tag' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>
            {/* {JSON.stringify(record.row.original?.tag_ids || null)} */}
            {record.row.original?.tag_ids?.map((tagId) => {
              const tag = tagsList.find((tag) => tag._id === tagId);
              return (
                <Badge key={tagId} className='mb-2 mr-2 text-xs'>
                  {tag?.name}
                </Badge>
              );
            })}
          </div>
        </div>
      ),
    },
    // {
    //   accessorKey: 'plan',
    //   id: 'plan',
    //   header: ({ column }) => (
    //     <DataTableColumnHeader column={column} title='Plan' />
    //   ),
    //   cell: (record) => (
    //     <div>
    //       <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
    //     </div>
    //   ),
    // },
    {
      accessorKey: 'user_id',
      id: 'user_id',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Contact Owner' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>
            {(() => {
              const agent = agentsList.find(
                (agent) => agent._id === record?.row?.original?.user_id
              );
              return `${agent?.first_name} ${agent?.last_name}`;
            })()}
          </div>
        </div>
      ),
    },
    // {
    //   accessorKey: 'postCount',
    //   id: 'postCount',
    //   header: ({ column }) => (
    //     <DataTableColumnHeader column={column} title='Post Count' />
    //   ),
    //   cell: (record) => (
    //     <div>
    //       <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
    //     </div>
    //   ),
    // },
    {
      accessorKey: 'followers_count',
      id: 'followers_count',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Followers Count' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
        </div>
      ),
    },
    {
      accessorKey: 'following_count',
      id: 'following_count',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Following Count' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
        </div>
      ),
    },
    // {
    //   accessorKey: 'bio',
    //   id: 'bio',
    //   header: ({ column }) => (
    //     <DataTableColumnHeader column={column} title='Bio' />
    //   ),
    //   cell: (record) => (
    //     <div>
    //       <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <ManualDataTable
        columns={columns}
        toolbar={CrmContactTableToolbar}
        data={crmContactList}
        hideTheseColumnsInSm={{}}
        pageCount={lastPageIndex}
        onPaginationChange={setPagination}
        pagination={pagination}
      />
    </div>
  );
};

export default CrmContactsTable;
