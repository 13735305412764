import { Outlet } from 'react-router-dom';

import SettingLayout from '@/components/layouts/SettingsLayout';

export default function Settings() {
  return (
    <>
      <SettingLayout>
        <Outlet />
      </SettingLayout>
    </>
  );
}
