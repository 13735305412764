import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@/context/ThemeProvider';
import useReportStore from '@/stores/ReportStore';
import { addDays, endOfWeek, format, startOfWeek } from 'date-fns';
import { CalendarIcon, Loader2Icon } from 'lucide-react';
import ReactApexChart from 'react-apexcharts';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { fetchAgentActivityData } from '@/hooks/report';

import { Button } from '../ui/button';
import { Calendar } from '../ui/calendar';
import { Checkbox } from '../ui/checkbox';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Separator } from '../ui/separator';

const AgentActivityChart = () => {
  // const today = new Date();
  // const yesterday = addDays(today, -1);

  // Assuming you want the week to start on Sunday, which is the default
  const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 }); // For a week starting on Monday
  const endOfThisWeek = endOfWeek(new Date(), { weekStartsOn: 1 }); // For a week starting on Monday

  const { agentActivityData, setAgentActivityData } = useReportStore(
    useShallow((state) => ({
      agentActivityData: state.agentActivityData,
      setAgentActivityData: state.setAgentActivityData,
    }))
  );
  const [loading, setLoading] = useState(false);
  const [groupContactEnabled, setGroupContactEnabled] = useState(false);
  const { currentTheme } = useTheme();

  const [date, setDate] = useState({
    from: startOfThisWeek,
    to: endOfThisWeek,
  });

  const apexData = {
    options: {
      chart: {
        background: 'transparent',
        type: 'bar',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: currentTheme === 'light' ? ['#304758'] : ['#ffffff'],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        title: {
          text: 'Days',
        },
        categories: agentActivityData ? agentActivityData.xaxis.categories : [],
      },
      yaxis: {
        max: agentActivityData ? agentActivityData.yaxis.max + 100 : 100,
        title: {
          text: 'Count',
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        shared: true,
        intersect: false,
        followCursor: true,
      },
      theme: {
        mode: currentTheme,
      },
    },
    series: agentActivityData ? agentActivityData.series : [],
  };

  const fetchAgentActivity = useCallback(
    async (startDate, endDate, groupContact) => {
      setLoading(true);
      try {
        const response = await fetchAgentActivityData(
          format(startDate, 'yyyy-MM-dd'),
          format(endDate, 'yyyy-MM-dd'),
          groupContact
        );
        setAgentActivityData(response);
      } catch (error) {
        toast.error('Unable to fetch agent activity data.');
      } finally {
        setLoading(false);
      }
    },
    [setAgentActivityData]
  );

  useEffect(() => {
    if (date) {
      fetchAgentActivity(date.from, date.to, groupContactEnabled);
    }
  }, [fetchAgentActivity, date, groupContactEnabled]);

  return (
    <div className='space-y-6'>
      <div className='flex justify-between'>
        <div>
          <h3 className='text-xl font-medium'>Agent Activity Chart</h3>
          <p className='text-sm text-muted-foreground'>
            View agent activity statistics.
          </p>
        </div>
      </div>
      <Separator />
      <div>
        <div className='mt-2'>
          <div className='my-2 flex flex-col items-start justify-center'>
            <div className='my-2 flex flex-row items-center justify-center'>
              <span>Date Range</span>
              <div className='relative ml-2 mr-1 inline-block '>
                <DatePickerWithRange date={date} setDate={setDate} />
              </div>
            </div>
            <div className='flex items-center justify-center'>
              <label htmlFor='contactGroup'>Group Messages by Contacts</label>
              <Checkbox
                id='contactGroup'
                className='mx-4'
                checked={groupContactEnabled}
                onCheckedChange={() =>
                  setGroupContactEnabled(!groupContactEnabled)
                }
              />
              {/* <input
              className='mx-2'
              type='checkbox'
              checked={groupContactEnabled}
              onChange={() => setGroupContactEnabled(!groupContactEnabled)}
            /> */}
            </div>
          </div>
          <>
            {loading && !agentActivityData ? (
              <div className='mt-10 flex h-[500px] w-full border p-1'>
                <div className='m-auto'>
                  <Loader2Icon className='h-4 w-4 animate-spin' />
                </div>
              </div>
            ) : agentActivityData ? (
              <div className='mt-10 h-[500px] w-full border p-1'>
                <ReactApexChart
                  options={apexData.options}
                  type='bar'
                  series={apexData.series}
                  width='100%'
                  height='98%'
                />
              </div>
            ) : (
              <div className='mt-10 flex h-[500px] w-full items-center justify-center border p-1'>
                <div>No data to display</div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default AgentActivityChart;

export function DatePickerWithRange({ date, setDate, className }) {
  const [tempDate, setTempDate] = useState(date);
  const handleDateChange = () => {
    setDate(tempDate);
  };
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover onOpenChange={handleDateChange}>
        <PopoverTrigger asChild>
          <Button
            id='date'
            variant={'outline'}
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !date && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className='mr-2 h-4 w-4' />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} -{' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0' align='start'>
          <Calendar
            initialFocus
            mode='range'
            defaultMonth={tempDate?.from}
            selected={tempDate}
            onSelect={setTempDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
