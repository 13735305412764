import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';
import { Outlet } from 'react-router-dom';

import { getToken } from '@/lib/utils';

export default function AblyLayout() {
  const token = getToken();

  const client = Ably.Realtime.Promise({
    authUrl: `${import.meta.env.VITE_API_URL}/api/websocket/create-client`,
    authMethod: 'GET',
    authHeaders: { Authorization: `Bearer ${token}` },
    autoConnect: false,
  });

  return (
    <AblyProvider client={client}>
      <Outlet />
    </AblyProvider>
  );
}
