import { useCallback, useMemo } from 'react';
import useInboxStore from '@/stores/InboxStore';
import { useHotkeys } from '@blueprintjs/core';
import { ArrowDownWideNarrow, ArrowUpWideNarrow } from 'lucide-react';
import { useShallow } from 'zustand/react/shallow';

import { trackSortFilterButtonClicked } from '@/lib/analytics-event';

import TooltipComponent from '../common/tooltip/tooltip';
import { Button } from '../ui/button';

const ConversationSortPicker = () => {
  const { selectedSortOrder, setSelectedSortOrder } = useInboxStore(
    useShallow((state) => ({
      selectedSortOrder: state.selectedSortOrder,
      setSelectedSortOrder: state.setSelectedSortOrder,
    }))
  );

  const handleOrderSelect = useCallback(() => {
    if (selectedSortOrder === 'desc') {
      setSelectedSortOrder('asc');
    } else {
      setSelectedSortOrder('desc');
    }
    trackSortFilterButtonClicked('mouse');
  }, [selectedSortOrder, setSelectedSortOrder]);

  const handleASCKeyPress = useCallback(() => {
    setSelectedSortOrder('asc');
    trackSortFilterButtonClicked('keyboard');
  }, [setSelectedSortOrder]);

  const handleDESCKeyPress = useCallback(() => {
    setSelectedSortOrder('desc');
    trackSortFilterButtonClicked('keyboard');
  }, [setSelectedSortOrder]);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'shift + arrowup',
        global: true,
        group: 'Filtering',
        // disabled: !contactSidebarShow,
        label: 'Chat Sort Order - Ascending',
        onKeyUp: handleASCKeyPress,
      },
      {
        combo: 'shift + arrowdown',
        global: true,
        group: 'Filtering',
        // disabled: !contactSidebarShow,
        label: 'Chat Sort Order - Descending',
        onKeyUp: handleDESCKeyPress,
      },
    ],
    [handleASCKeyPress, handleDESCKeyPress]
  );
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  return (
    <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
      <TooltipComponent
        content={
          <div className='flex items-center space-x-2'>
            <div>Sort</div>
            <div className='flex space-x-1'>
              <kbd className='pointer-events-none  hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
                shift
              </kbd>
              <kbd className='pointer-events-none  hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
                uparrow
              </kbd>
              <div>{'/'}</div>
              <kbd className='pointer-events-none  hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
                downarrow
              </kbd>
            </div>
          </div>
        }
      >
        <Button
          variant='ghost'
          onClick={handleOrderSelect}
          size='sm'
          className='px-2'
        >
          {selectedSortOrder === 'desc' ? (
            <ArrowDownWideNarrow className='h-6 w-6' />
          ) : (
            <ArrowUpWideNarrow className='h-6 w-6' />
          )}
        </Button>
      </TooltipComponent>
    </div>
  );
};

export default ConversationSortPicker;
