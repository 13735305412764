import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = {
  conversations: [],
  conversationCount: 0,
  page: 0,
  socketUpdateCount: 0,

  tags: [],
  owners: [],

  selectedTags: [],
  selectedOwners: [],
  searchText: '',
  selectedConversationStatus: { value: 'open', label: 'Open' },
  selectedSortOrder: 'desc',

  contactSidebarShow: true,

  focusedIndex: 0,

  /*
  ConversationListUpdateStatusCode:
  - 0: New Conversation
  - 1: Update Existing Conversation List
  - 2: Side effect of ConversationListUpdateStatusCode - 1 are completed
  */
  conversationListUpdateStatusCode: 0,
};

const useInboxStore = create(
  devtools(
    (set) => ({
      ...initialState,

      // conversations
      setConversations: (conversations, page, count) =>
        set({
          conversations,
          page,
          conversationCount: count,
        }),
      setNextConversations: (conversations, page, count) =>
        set((state) => ({
          conversations: [...state.conversations, ...conversations],
          page: page,
          count: count,
        })),
      removeConversation: (contactId) =>
        set((state) => ({
          conversations: state.conversations.filter(
            (contact) => contact._id !== contactId
          ),
        })),
      updateConversationMessageSocket: (contactData) =>
        set((state) => {
          // Find the index of the conversation
          const index = state.conversations.findIndex(
            (item) => item._id === contactData._id
          );
          console.log(
            'index -> ',
            index,
            contactData.latest_message_text,
            contactData.latest_message_timestamp
          );

          if (index === -1) {
            // If conversation not found, push the conversation to the top
            return {
              ...state,
              conversations: [contactData, ...state.conversations],
            };
          } else {
            // If conversation found, Update the conversation
            const updatedConversations = [
              {
                ...state.conversations[index],
                latest_message_text: contactData.latest_message_text,
                latest_message_timestamp: contactData.latest_message_timestamp,
              },
              ...state.conversations.slice(0, index),
              ...state.conversations.slice(index + 1),
            ];

            // Return the new state
            return { ...state, conversations: updatedConversations };
          }
        }),
      updateConversationNewMessage: (id, message, timestamp) =>
        set((state) => {
          // Find the index of the conversation
          const index = state.conversations.findIndex(
            (item) => item._id === id
          );

          if (index === -1) {
            // If conversation not found, just return
            return { state };
          } else {
            // If conversation found, Update the conversation
            const updatedConversations = [
              {
                ...state.conversations[index],
                latest_message_text: message,
                latest_message_timestamp: timestamp,
              },
              ...state.conversations.slice(0, index),
              ...state.conversations.slice(index + 1),
            ];

            // Return the new state
            return { ...state, conversations: updatedConversations };
          }
        }),
      addConversationToTop: (contactData) =>
        set((state) => {
          return {
            ...state,
            conversations: [contactData, ...state.conversations],
          };
        }),
      increaseConversationCount: () =>
        set((state) => ({ conversationCount: state.conversationCount + 1 })),
      decreaseConversationCount: () =>
        set((state) => ({ conversationCount: state.conversationCount - 1 })),
      incrementSocketUpdateCount: () =>
        set((state) => ({ socketUpdateCount: state.socketUpdateCount + 1 })),
      resetSocketCount: () => set({ socketUpdateCount: 0 }),

      // filters settings
      setTags: (tags) =>
        set((state) => {
          const otherTagConditions = [
            { _id: 'No-Tag-SBCCRM', name: 'No Tags' },
            { _id: 'Seen-Not-Responded', name: 'Seen & Not Responded' },
            { _id: 'Not-Responded', name: 'Not Responded' },
          ];

          return {
            ...state,
            tags: [...tags, ...otherTagConditions],
          };
        }),
      addTags: (newTag) => set((state) => ({ tags: [...state.tags, newTag] })),
      setOwners: (owners) => set({ owners }),

      setSelectedTags: (tags) =>
        set({
          selectedTags: tags,
        }),
      setSelectedOwners: (owners) =>
        set({
          selectedOwners: owners,
        }),
      setConversationStatus: (status) =>
        set({
          selectedConversationStatus: status,
        }),
      setSearchText: (searchText) =>
        set({
          searchText,
        }),
      setSelectedSortOrder: (sortOrder) =>
        set({
          selectedSortOrder: sortOrder,
        }),

      setContactSidebarShow: () =>
        set((state) => ({ contactSidebarShow: !state.contactSidebarShow })),

      setFocusedIndex: (index) => set({ focusedIndex: index }),

      setConversationListUpdateStatusCode: (code) =>
        set({ conversationListUpdateStatusCode: code }),
      resetInboxStore: () => set(initialState),

      //primaryTag for selecting plan after setting that tag
      primaryTag: 'Closed Won',
      setPrimaryTag: (tag) => set({ primaryTag: tag }),
    }),
    { name: 'InboxStore' }
  )
);

export default useInboxStore;

/* 
tags -> list
owners -> list

selectedTag
selectedOwner
selectedSort
searchText
selectedConversationState


*/
