import { useCallback } from 'react';
import useInboxStore from '@/stores/InboxStore';
import { Transition } from '@headlessui/react';
import { ReloadIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { fetchConversations } from '@/hooks/inbox';

import TooltipComponent from '../common/tooltip/tooltip';
import { Button } from '../ui/button';

export default function ReloadConversationComponent() {
  const {
    selectedTags,
    selectedOwners,
    searchText,
    selectedConversationStatus,
    selectedSortOrder,
    setConversationListUpdateStatusCode,
  } = useInboxStore(
    useShallow((state) => ({
      selectedTags: state.selectedTags,
      selectedOwners: state.selectedOwners,
      searchText: state.searchText,
      selectedConversationStatus: state.selectedConversationStatus,
      selectedSortOrder: state.selectedSortOrder,
      setConversationListUpdateStatusCode:
        state.setConversationListUpdateStatusCode,
    }))
  );

  const navigate = useNavigate();

  const { setConversations } = useInboxStore(
    useShallow((state) => ({
      setConversations: state.setConversations,
    }))
  );

  const fetchData = useCallback(
    async (type, page) => {
      try {
        const response = await fetchConversations(
          {
            tags: selectedTags,
            agents: selectedOwners,
            searchText: searchText,
          },
          page,
          selectedConversationStatus,
          selectedSortOrder
        );
        if (type === 'new') {
          const { data, next_page_url, current_page, total } = response;
          setConversations(
            data,
            next_page_url ? current_page + 1 : null,
            total
          );

          navigate('/inbox');

          setConversationListUpdateStatusCode(0);
        }
      } catch (error) {
        console.error('error fetching conversations:', error);
      }
    },
    [
      navigate,
      setConversations,
      selectedTags,
      selectedOwners,
      searchText,
      selectedConversationStatus,
      selectedSortOrder,
      setConversationListUpdateStatusCode,
    ]
  );

  return (
    <TooltipComponent
      content={
        <div className='flex items-center space-x-2'>
          <div>Refresh Conversations</div>
        </div>
      }
    >
      <Transition
        appear={true}
        show={searchText ? true : false}
        enter='transition-opacity duration-150'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <Button
          variant='ghost'
          onClick={() => fetchData('new', 1)}
          size='sm'
          className={cn('px-2')}
        >
          <ReloadIcon className='h-5 w-5 cursor-pointer' />
        </Button>
      </Transition>
    </TooltipComponent>
  );
}
