import { useState } from 'react';
import usePlanStore from '@/stores/PlanStore';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { deletePlan } from '@/hooks/plans';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { ButtonLoading } from '@/components/common/button/loading-button';

const DeletePlanConfirmationModal = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    plans,
    setPlans,
    selectedPlanId,
    selectedPlanName,
    selectedPlanAmount,
    deleteModalStatus,
    setDeleteModalStatus,
  } = usePlanStore(
    useShallow((state) => ({
      plans: state.plans,
      setPlans: state.setPlans,
      selectedPlanId: state.selectedPlanId,
      selectedPlanName: state.selectedPlanName,
      selectedPlanAmount: state.selectedPlanAmount,
      deleteModalStatus: state.deleteModalStatus,
      setDeleteModalStatus: state.setDeleteModalStatus,
    }))
  );

  const onDelete = async () => {
    try {
      setIsLoading(true);
      await deletePlan(selectedPlanId);
      setPlans(plans.filter((plan) => plan._id !== selectedPlanId));

      setIsLoading(false);
      toast.success('Plan deleted successfully.');
      setDeleteModalStatus(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={deleteModalStatus} onOpenChange={setDeleteModalStatus}>
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>Delete Plan</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete the plan with the following details?
            <br />
            <strong>Plan Name:</strong> {selectedPlanName}
            <br />
            <strong>Amount:</strong> ${selectedPlanAmount}
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button onClick={() => setDeleteModalStatus(false)}>Cancel</Button>
          {isLoading ? (
            <ButtonLoading />
          ) : (
            <Button onClick={onDelete}>Delete</Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeletePlanConfirmationModal;
