import { useEffect, useState } from 'react';
import {
  Cross2Icon,
  TextAlignBottomIcon,
  TextAlignTopIcon,
} from '@radix-ui/react-icons';
import { Outlet, useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import ConversationAgentPicker from '@/components/inbox/ConversationAgentPicker';
import ConversationsList from '@/components/inbox/ConversationList';
import ConversationSortPicker from '@/components/inbox/ConversationSortPicker';
import ConversationStatusPicker from '@/components/inbox/ConversationStatusPicker';
import ConversationTagPicker from '@/components/inbox/ConversationTagPicker';
import InboxHeader from '@/components/inbox/InboxHeader';

function Inbox() {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Inbox');
  }, []);

  return (
    <>
      <div className='hidden h-screen space-y-6 md:block'>
        <div className='flex h-full flex-col lg:flex-row lg:space-y-0'>
          <aside className='flex h-screen flex-col  lg:min-w-[380px] lg:max-w-[380px]'>
            <div className='space-y-3 px-3 py-4'>
              <h2
                className='w-fit cursor-pointer text-xl font-bold tracking-tight text-primary'
                onClick={() =>
                  navigate('/inbox', {
                    replace: true,
                  })
                }
              >
                Inbox
              </h2>
              <InboxHeader />
            </div>

            <ConversationsList />
          </aside>
          <Separator orientation='vertical' />
          <div className='flex-1 '>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default Inbox;
