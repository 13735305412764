import { agentList, assignAgent } from '@/constants/endpoints';

import { APIClient } from '@/lib/ApiClient';

const apiClient = new APIClient();

export function fetchAgents() {
  return apiClient.get(agentList, null, true);
}

export function setNewAgent(agentId, contactId, clientId) {
  return apiClient.create(
    assignAgent,
    { user_id: agentId, contact_id: contactId },
    true,
    clientId
  );
}
