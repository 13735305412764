// Zustand store for User and Auth Details
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = {
  user: null,
  authDetails: null,
  customerId: null,
  clientId: null,
  ablyConnectionStatus: null,
};

// Sample user object
const useUserStore = create(
  devtools(
    (set) => ({
      ...initialState,
      setUser: (user) => set({ user }, false, 'set_user'),
      setAuthDetails: (authDetails) => set({ authDetails }),

      setCustomer: (customerId) => set({ customerId }),

      setClientId: (clientId) => set({ clientId }),

      setAblyConnectionStatus: (status) =>
        set({ ablyConnectionStatus: status }),

      resetUserStore: () => set({ ...initialState }),
    }),
    { name: 'userStore' }
  )
);

export default useUserStore;
