import { CheckCheckIcon } from 'lucide-react';

import { cn } from '@/lib/utils';

export default function VideoMessage({ direction, media_url, is_seen, emoji }) {
  return (
    <>
      <video
        controls
        preload='metadata'
        className='max-h-[350px] min-h-[350px] max-w-[350px] rounded-md'
      >
        <source src={media_url} type='video/mp4' />
      </video>
      {is_seen && (
        <span className={'absolute -bottom-2 right-0'}>
          <CheckCheckIcon className='h-4 w-4 text-seen' />
        </span>
      )}
      {emoji && (
        <span
          className={cn(
            'absolute -bottom-3 rounded-full border bg-primary-foreground px-[2px] text-xs',
            direction !== 'sent' ? 'left-3' : is_seen ? 'right-5' : 'right-2'
          )}
        >
          {emoji}
        </span>
      )}
    </>
  );
}
