import useContactStore from '@/stores/ContactStore';
import useInboxStore from '@/stores/InboxStore';
import useUserStore from '@/stores/UserStore';
import { LogOut, SquareUser } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  trackLogOutAndMixpanelReset,
  trackUserProfileClicked,
} from '@/lib/analytics-event';
import { removeToken } from '@/lib/utils';

import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

export function UserAvatarMenu() {
  const { user, resetUserStore } = useUserStore(
    useShallow((state) => ({
      user: state.user,
      resetUserStore: state.resetUserStore,
    }))
  );

  const resetContactStore = useContactStore((state) => state.resetContactStore);

  const resetInboxStore = useInboxStore((state) => state.resetInboxStore);

  const navigate = useNavigate();

  if (!user) return null;

  const handleLogout = () => {
    removeToken();

    // reset user store
    resetUserStore();
    // reset contact store
    resetContactStore();
    // reset inbox store
    resetInboxStore();

    // reset mixpanel
    trackLogOutAndMixpanelReset();
    // navigate to login page
    navigate('/login');
  };

  const handleProfile = () => {
    trackUserProfileClicked();
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar>
          <AvatarImage src={user.profile_picture_url} />
          <AvatarFallback className='bg-primary-foreground text-primary'>
            {user.first_name.charAt(0)}
            {user.last_name.charAt(0)}
          </AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start'>
        <DropdownMenuItem onClick={handleProfile}>
          Profile
          <DropdownMenuShortcut>
            <SquareUser />
          </DropdownMenuShortcut>
        </DropdownMenuItem>

        <DropdownMenuItem onClick={handleLogout}>
          Logout
          <DropdownMenuShortcut>
            <LogOut />
          </DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
