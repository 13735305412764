import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = {
  agentActivityData: null,
};

const useReportStore = create(
  devtools(
    (set) => ({
      ...initialState,

      setAgentActivityData: (data) =>
        set({
          agentActivityData: data,
        }),
    }),

    { name: 'ReportStore' }
  )
);

export default useReportStore;
