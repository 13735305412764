import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const usePlanStore = create(
  devtools(
    (set) => ({
      plans: [],
      setPlans: (plans) => set({ plans }),

      // Plan modal status and type
      planModalStatus: false,
      setPlanModalStatus: (planModalStatus) => set({ planModalStatus }),

      planModalType: 'create', // create or edit
      setPlanModalType: (planModalType) => set({ planModalType }),
      // Selected plan for edit
      selectedPlanId: '',
      setSelectedPlanId: (selectedPlanId) => set({ selectedPlanId }),

      selectedPlanName: '',
      setSelectedPlanName: (selectedPlanName) => set({ selectedPlanName }),

      selectedPlanAmount: 0,
      setSelectedPlanAmount: (selectedPlanAmount) =>
        set({ selectedPlanAmount }),

      // Delete modal status
      deleteModalStatus: false,
      setDeleteModalStatus: (deleteModalStatus) => set({ deleteModalStatus }),

      // Plan Selection Modal
      planSelectionModalStatus: false,
      setPlanSelectionModalStatus: (planSelectionModalStatus) =>
        set({ planSelectionModalStatus }),
    }),
    { name: 'PlanStore' }
  )
);

export default usePlanStore;
