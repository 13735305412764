import { UnplugIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import useUserStore from '../../stores/UserStore';

export default function ConnectionBanner() {
  const connectionStatus = useUserStore((state) => state.ablyConnectionStatus);
  const navigate = useNavigate();

  console.log('connection status -> ', connectionStatus);

  const negativeState = ['disconnected', 'suspended', 'failed'];
  const ignoredStates = ['connected', null, 'closed'];

  return ignoredStates.includes(connectionStatus) ? null : (
    <div className='fixed start-0 top-0 z-50 flex w-full justify-between border-b bg-primary p-4 '>
      <div className='mx-auto flex items-center'>
        <p className='flex items-center text-sm font-normal text-primary-foreground'>
          <span className='me-3 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-secondary p-1'>
            <UnplugIcon className='text-primary' />
            <span className='sr-only'>Unplug Icon</span>
          </span>
          <span>
            Connection to the server is lost. Please{' '}
            <span
              onClick={() =>
                navigate(0, {
                  replace: true,
                })
              }
              className='decoration-600 inline cursor-pointer font-medium underline decoration-solid underline-offset-2 hover:no-underline'
            >
              refresh the page
            </span>
          </span>
        </p>
      </div>
    </div>
  );
}
