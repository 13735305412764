import { useCallback, useMemo, useRef, useState } from 'react';
import useInboxStore from '@/stores/InboxStore';
import { useHotkeys } from '@blueprintjs/core';
import { Cross2Icon, ReloadIcon } from '@radix-ui/react-icons';
import { useDebouncedCallback } from 'use-debounce';
import { useShallow } from 'zustand/react/shallow';

import { trackResetFilterButtonClicked } from '@/lib/analytics-event';

import KeyboardShortcut from '../common/keyboard/keyboard';
import TooltipComponent from '../common/tooltip/tooltip';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import ConversationAgentPicker from './ConversationAgentPicker';
import ConversationSearch from './ConversationSearch';
import ConversationSortPicker from './ConversationSortPicker';
import ConversationStatusPicker from './ConversationStatusPicker';
import ConversationTagPicker from './ConversationTagPicker';
import ReloadConversationComponent from './ReloadConversationComponent';

export default function InboxHeader() {
  const { selectedTags, selectedOwners } = useInboxStore(
    useShallow((state) => ({
      selectedTags: state.selectedTags,
      selectedOwners: state.selectedOwners,
    }))
  );

  const { setSelectedTags, setSelectedOwners } = useInboxStore(
    useShallow((state) => ({
      setSearchText: state.setSearchText,
      setSelectedTags: state.setSelectedTags,
      setSelectedOwners: state.setSelectedOwners,
    }))
  );

  const resetFilters = useCallback(
    (mode) => {
      setSelectedTags([]);
      setSelectedOwners([]);
      trackResetFilterButtonClicked(mode);
    },
    [setSelectedTags, setSelectedOwners]
  );

  const hotkeys = useMemo(
    () => [
      {
        combo: 'shift + R',
        global: true,
        group: 'Filtering',
        disabled: !selectedTags.length && !selectedOwners.length,
        label: 'Reset Tags and Owners',
        onKeyUp: () => resetFilters('keyboard'),
      },
    ],
    [resetFilters, selectedOwners, selectedTags]
  );

  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  return (
    <div className='space-y-3'>
      <ConversationSearch />
      <div className='flex flex-row items-center justify-between'>
        <ConversationStatusPicker />
        <div className='flex flex-row items-center justify-center space-x-1'>
          <ReloadConversationComponent />
          <ConversationSortPicker />
        </div>
      </div>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row space-x-2'>
          <div>
            <ConversationTagPicker />
          </div>
          <div>
            <ConversationAgentPicker />
          </div>
        </div>
        {(selectedTags.length > 0 || selectedOwners.length > 0) && (
          <TooltipComponent
            content={
              <div className='flex space-x-2'>
                <div>Reset</div>
                <KeyboardShortcut keys={['shift', 'R']} />
              </div>
            }
          >
            <Button
              variant='ghost'
              onClick={() => resetFilters('mouse')}
              className='h-8 select-none px-2'
            >
              Reset
              <Cross2Icon className='ml-2 h-4 w-4' />
            </Button>
          </TooltipComponent>
        )}
      </div>
    </div>
  );
}
