/*
Ref:
  - https://medium.com/@aylo.srd/server-side-pagination-and-sorting-with-tanstack-table-and-react-bd493170125e
*/
import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  // VisibilityState,
} from '@tanstack/react-table';

import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
// import { useTailwindBreakpoint } from '@/lib/use-breakpoint';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

import { DataTablePagination } from './data-table-pagination';

export function ManualDataTable({
  columns,
  data,
  hideTheseColumnsInSm = {},
  toolbar,
  pageCount,
  onPaginationChange,
  pagination,
}) {
  // const breakpoint = useTailwindBreakpoint();

  const [rowSelection, setRowSelection] = React.useState({});
  // const [columnVisibility, setColumnVisibility] = React.useState(
  //   breakpoint === 'sm' ? hideTheseColumnsInSm : {}
  // );
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [sorting, setSorting] = React.useState([]);

  const table = useReactTable({
    data,
    columns,
    initialState: {},
    state: {
      sorting,
      // columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
    },

    getCoreRowModel: getCoreRowModel(),

    manualPagination: true,
    pageCount,
    onPaginationChange,
  });

  // useEffect(() => {
  //   if (breakpoint === 'sm') {
  //     setColumnVisibility(hideTheseColumnsInSm);
  //   } else {
  //     setColumnVisibility({});
  //   }
  // }, [breakpoint, hideTheseColumnsInSm]);

  return (
    <div className='space-y-4'>
      {toolbar ? toolbar({ table }) : <></>}

      <div className='overflow-x-auto rounded-md border'>
        <ScrollArea>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className='h-24 text-center'
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <ScrollBar orientation='horizontal' />
        </ScrollArea>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}
