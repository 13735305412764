import { useCallback, useMemo, useRef, useState } from 'react';
import useContactStore from '@/stores/ContactStore';
import useInboxStore from '@/stores/InboxStore';
import useUserStore from '@/stores/UserStore';
import { useHotkeys } from '@blueprintjs/core';
import { SendHorizonalIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import {
  trackContactStatusChange,
  trackMessageInputClicked,
  trackQuickCloseContactFeature,
} from '@/lib/analytics-event';
import { updateContactStatus } from '@/hooks/contact';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';

export default function MessageInput({ addMessage }) {
  const [message, setMessage] = useState('');
  const textareaRef = useRef(null);

  const navigate = useNavigate();

  const clientId = useUserStore((state) => state.clientId);
  const {
    conversations,
    removeConversation,
    increaseConversationCount,
    decreaseConversationCount,
    selectedConversationStatus,
  } = useInboxStore(
    useShallow((state) => ({
      conversations: state.conversations,
      removeConversation: state.removeConversation,
      increaseConversationCount: state.increaseConversationCount,
      decreaseConversationCount: state.decreaseConversationCount,
      selectedConversationStatus: state.selectedConversationStatus,
    }))
  );

  const { id, status } = useContactStore(
    useShallow((state) => ({
      id: state._id,
      status: state.status,
    }))
  );

  const handleMessage = async (e, textMessage) => {
    e.preventDefault();
    //if text value is not emptry then call onaddMessage function
    if (textMessage !== '') {
      setMessage('');

      return await addMessage(textMessage, 'text');
    }
  };

  const updateStatus = async () => {
    try {
      const newStatus = status === 'open' ? 'close' : 'open';
      const response = await updateContactStatus(id, newStatus, clientId);
      trackContactStatusChange(newStatus);

      return response;
    } catch (error) {
      toast.dismiss('sending-message');
      toast.error('Unable to update contact status');
    }
  };

  const handleKeyDown = async (e) => {
    // If ctrl + enter is pressed, send message and remove focus from textarea
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      try {
        toast.loading('Loading...', {
          id: 'sending-message',
        });

        trackQuickCloseContactFeature();

        const response = await handleMessage(e, message);
        textareaRef?.current.blur();

        const newStatus = status === 'open' ? 'close' : 'open';

        // If status is open, update status to close
        const updateStatusResponse = await updateStatus();

        if (response?.status === 'success' && updateStatusResponse) {
          toast.dismiss('sending-message');
          toast.success('Message sent and status updated');
          if (selectedConversationStatus.value !== 'all') {
            switch (selectedConversationStatus.value) {
              // If the contact status is changed to the selectedConversationStatus
              case newStatus:
                console.log('insert conversation triggered', id);
                // addConversationToTop(contactData);
                increaseConversationCount();
                break;

              // If the contact status is changed to the opposite of selectedConversationStatus
              default:
                console.log('remove conversation triggered', id);
                removeConversation(id);
                decreaseConversationCount();
                break;
            }
          }

          // Find id index in conversations array
          const index = conversations.findIndex(
            (conversation) => conversation._id === id
          );

          // If index is found, navigate to next conversation
          // If index (-1) is not found, do not navigate
          // if index is last element, do not navigate

          if (index !== -1 && index !== conversations.length - 1) {
            navigate(`/inbox/${conversations[index + 1]._id}`, {
              replace: true,
            });
          } else {
            navigate(`/inbox`, {
              replace: true,
            });
          }
        }
      } catch (error) {
        toast.dismiss('sending-message');
        toast.error('Unable to send message');
      }
    } else if (e.key === 'Enter') {
      // If Shift key is also pressed, insert a newline character
      if (e.shiftKey) {
        // dont do anything
      } else {
        // Otherwise, prevent the default behavior (submitting the form) and perform your button click action
        e.preventDefault();
        handleMessage(e, message);
      }
    }

    // If esc is pressed, remove focus from textarea
    if (e.key === 'Escape') {
      textareaRef?.current.blur();
    }
  };

  const handleKeyPress = useCallback(() => {
    textareaRef?.current.focus();
    trackMessageInputClicked('keyboard');
  }, []);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'R',
        global: true,
        group: 'Contact Chat',
        // disabled: !contactSidebarShow,
        label: 'Reply',
        onKeyUp: handleKeyPress,
      },
    ],
    [handleKeyPress]
  );
  const hotkey = useHotkeys(hotkeys);

  return (
    <>
      <Separator orientation={'horizontal'} />
      <div className='m-3 flex max-h-80 flex-grow-0 items-center justify-center space-x-3'>
        <Textarea
          ref={textareaRef}
          rows='3'
          className='min-h-2 resize-none overflow-y-hidden'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onClick={() => trackMessageInputClicked('mouse')}
          placeholder={
            // 'Type a message here. Press Shift + Enter to add a new line.'
            'To type a message, press R. To add a new line, press Shift + Enter.'
          }
          onKeyDown={handleKeyDown}
        />
        <Button size='' onClick={(e) => handleMessage(e, message)}>
          <SendHorizonalIcon />
        </Button>
      </div>
    </>
  );
}
