import { Outlet } from 'react-router-dom';

import ReportsLayout from '@/components/layouts/ReportsLayout';

export default function Reports() {
  return (
    <>
      <ReportsLayout>
        <Outlet />
      </ReportsLayout>
    </>
  );
}
