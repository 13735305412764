import { Outlet } from 'react-router-dom';

import { Toaster } from '../ui/sonner';

export default function RootLayout() {
  return (
    <>
     <Toaster
        toastOptions={{
          className: 'font-sans',
        }}
      />
      <Outlet />
    </>
  );
}
