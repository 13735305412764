import { useEffect } from 'react';
import useTeamMemberStore from '@/stores/TeamMemberStore';

// import { fetchAgents } from '@/hooks/agent';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

import { DataTable } from '../common/table/data-table';
import { DataTableColumnHeader } from '../common/table/data-table-column-header';
import { Button } from '../ui/button';
import { Separator } from '../ui/separator';
import CreateAgentModal from './team-members/create-agent';

const TeamMembers = () => {
  const columns = [
    {
      accessorKey: 'name',
      id: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Name' />
      ),
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div className='flex items-center gap-3'>
            <Avatar>
              <AvatarImage src={item.profile_picture_url} />
              <AvatarFallback>
                {item.first_name.charAt(0)}
                {item.last_name.charAt(0)}
              </AvatarFallback>
            </Avatar>
            <div className='mb-1 text-sm font-medium'>
              {item.first_name} {item.last_name}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'email',
      id: 'email',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Email' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
        </div>
      ),
    },

    {
      accessorKey: 'role',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Role' />
      ),
      cell: ({ row }) => {
        return <> {row.getValue('role')} </>;
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
  ];

  const { agents, fetchAgents } = useTeamMemberStore((state) => ({
    agents: state.agents,

    fetchAgents: state.fetchAgents,
  }));

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  return (
    <div className='space-y-6'>
      <div className='flex justify-between'>
        <div>
          <h3 className='text-xl font-medium'>Team Members</h3>
          <p className='text-sm text-muted-foreground'>
            Manage your team members.
          </p>
        </div>
        <Button
          onClick={() =>
            useTeamMemberStore.setState({
              createAgentModalStatus: true,
            })
          }
        >
          Add Agents
        </Button>
      </div>
      <Separator />
      <DataTable columns={columns} data={agents} hideTheseColumnsInSm={{}} />

      <CreateAgentModal />
    </div>
  );
};

export default TeamMembers;
