import CrmContactsTable from '@/components/crm-contacts/crm-contacts-table/CrmContactTable';

const Contacts = () => {
  return (
    <div className='hidden h-full flex-1 flex-col space-y-8 p-8 md:flex'>
      <div className='flex items-center justify-between space-y-2'>
        <div>
          <h2 className='text-2xl font-bold tracking-tight'>Contacts</h2>
        </div>
      </div>

      <CrmContactsTable />
    </div>
  );
};

export default Contacts;
