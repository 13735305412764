import { clsx } from 'clsx';
import Cookies from 'js-cookie';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const isUserAuthenticated = () => {
  const token = getToken();

  if (!token) {
    console.log('user or token missing');
    return false;
  }

  try {
    // const decoded = jwtDecode(user.token);
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //   console.warn('access token expired');
    //   return false;

    // Handle Removal of token in cookies and user in Auth store if invalid token
    // } else {
    return true;
    // }
  } catch (e) {
    console.warn('access token expired');
    return false;
  }
};

export const getToken = () => {
  const token = Cookies.get('token');

  return token ? token : null;
};

export const setToken = (token) => {
  Cookies.set('token', token, { expires: 7 });
};

export const removeToken = () => {
  Cookies.remove('token');
};
