import { CheckCheckIcon } from 'lucide-react';

import { cn } from '@/lib/utils';

export default function AudioMessage({ media_url, direction, emoji, is_seen }) {
  return (
    <>
      <audio
        controls
        className='h-9 rounded-md bg-white'
        controlsList='nodownload nofullscreen noremoteplayback noplaybackrate'
      >
        <source src={media_url} type='video/mp4' />
      </audio>
      {is_seen && (
        <span className={'absolute -bottom-2 right-0'}>
          <CheckCheckIcon className='h-4 w-4 text-seen' />
        </span>
      )}
      {emoji && (
        <span
          className={cn(
            'absolute -bottom-3 rounded-full border bg-primary-foreground px-[2px] text-xs',
            direction !== 'sent' ? 'left-3' : is_seen ? 'right-5' : 'right-2'
          )}
        >
          {emoji}
        </span>
      )}
    </>
  );
}
