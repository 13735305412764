import { useEffect, useState } from 'react';
import useTeamMemberStore from '@/stores/TeamMemberStore';
import { useShallow } from 'zustand/react/shallow';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { UserRegisterForm } from '@/components/register/UserRegisterForm';

const CreateAgentModal = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    agents,
    setAgents,
    createAgentModalStatus,
    setCreateAgentModalStatus,
  } = useTeamMemberStore(
    useShallow((state) => ({
      agents: state.agents,
      setAgents: state.setAgents,

      createAgentModalStatus: state.createAgentModalStatus,
      setCreateAgentModalStatus: state.setCreateAgentModalStatus,
    }))
  );

  const onSubmit = async (data) => {};

  return (
    <Dialog
      open={createAgentModalStatus}
      onOpenChange={setCreateAgentModalStatus}
    >
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>Create Agent</DialogTitle>
          <DialogDescription>
            Agents are users who can access chat conversations and reply to
            customers.
          </DialogDescription>
          <UserRegisterForm />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default CreateAgentModal;
