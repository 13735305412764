import { login, register } from '@/constants/endpoints';

import { APIClient } from '@/lib/ApiClient';

const apiClient = new APIClient();

export function loginUser(email, password) {
  return apiClient.create(login, {
    email,
    password,
  });
}

export function registerUser(email, first_name, last_name, password) {
  return apiClient.create(register, {
    first_name,
    last_name,
    email,
    password,
  });
}
