import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { fetchAgents } from '@/hooks/agent';

const useTeamMemberStore = create(
  devtools(
    (set) => ({
      agents: [],
      setAgents: (agents) => set({ agents }),

      createAgentModalStatus: false,
      setCreateAgentModalStatus: (createAgentModalStatus) =>
        set({ createAgentModalStatus }),

      loading: false,
      hasErrors: false,
      fetchAgents: async () => {
        set({ loading: true });

        try {
          const response = await fetchAgents();
          set({ agents: response, loading: false });
        } catch (err) {
          console.error('Failed to fetch agents:', err);
          set(() => ({ hasErrors: true, loading: false }));
        }
      },
    }),
    { name: 'TeamMemberStore' }
  )
);

export default useTeamMemberStore;
