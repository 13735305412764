import { useEffect } from 'react';
import useUserStore from '@/stores/UserStore';
import { useAbly, useConnectionStateListener } from 'ably/react';
import { useShallow } from 'zustand/react/shallow';

import { trackAblyConnectionStatusChange } from '@/lib/analytics-event';

const AblyManager = () => {
  const { customerId, setClientId, setAblyConnectionStatus } = useUserStore(
    useShallow((state) => ({
      customerId: state.customerId,
      setClientId: state.setClientId,
      setAblyConnectionStatus: state.setAblyConnectionStatus,
    }))
  );
  const client = useAbly();

  useEffect(() => {
    const onConnected = () => {
      const clientId = client.auth.clientId;
      setClientId(clientId);
    };

    client.connection.once('connected', onConnected);

    return () => {
      client.connection.off();
    };
  }, [client.connection, setClientId, client.auth.clientId]);

  // Connect to Ably client when the customer details are available
  useEffect(() => {
    if (customerId) {
      client.connect();
    }
  }, [client, customerId]);

  useConnectionStateListener(
    ['connected', 'disconnected', 'suspended', 'closed', 'failed'],
    (stateChange) => {
      setAblyConnectionStatus(stateChange.current);
      trackAblyConnectionStatusChange(stateChange.current);
    }
  );

  return null; // This component does not render anything
};

export default AblyManager;
